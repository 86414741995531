import { calendarioService } from './../../shared/services/calendario.service';
import { IUsuarios } from './../../shared/models/usuarios';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService } from 'primeng/api';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/shared/services/http.service';
import ClientesProspecto from 'src/app/shared/models/ClientesProspecto';
import { Estatus } from 'src/app/shared/models/Estatus';
import { FormArray, FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import MediosDeContacto from 'src/app/shared/models/MediosDeContactoModel';
import Clasificacion from 'src/app/shared/models/Clasificacion';
import SubClasificacion from 'src/app/shared/models/SubClasificacion';
import { IResultadoGeneral } from 'src/app/shared/models/IResultadoGeneral';
import TipoProyecto from 'src/app/shared/models/TipoProyecto';
import { AuthService } from 'src/app/shared/services/auth.service';
//import TipoProyecto from 'src/app/shared/models/TipoProyecto';
//servicios
import { clasificacion } from 'src/app/shared/services/clasificacion.service';
import { subclasificacion } from 'src/app/shared/services/subclasificacion.service';
import { mediodecontacto } from 'src/app/shared/services/mediodecontacto.service';
import { clienteprospecto } from 'src/app/shared/services/clienteprospecto.service';
import User from 'src/app/shared/models/user';
import { tipoProyecto } from 'src/app/shared/services/tipoProyecto.service';
import ContactoEmpresa from 'src/app/shared/models/ContactoEmpresa';
import Estados_Ciudades from 'src/app/shared/models/Estados_Ciudades';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import IModeloEstatus from 'src/app/shared/models/ModeloEstatus';
import IMovimientosCteProspecto from 'src/app/shared/models/MomientosCteProspecto';
import UsuariosComercial from 'src/app/shared/models/UsuariosComercial';
import { IusuarioTarea } from 'src/app/shared/models/usuario_tareas';
import Iuploadevent from 'src/app/shared/models/uploadevent';
import IPuestos from 'src/app/shared/models/Puestos';
import IClasificacionPuestos from 'src/app/shared/models/ClasfiicacionPuestos';
import IEstadoMunicipio from 'src/app/shared/models/EstadoMunicipio';
import ISoporteCobranza from 'src/app/shared/models/SoporteCobranza';
import IConfiguracionUnidades from 'src/app/shared/models/ConfiguracionUnidades';
import IIndicadoresDesempeño from 'src/app/shared/models/IndicadoresDesempeño';
import IInfoBrief from 'src/app/shared/models/InfoBrief';
import { switchMap, timestamp } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import IArea from 'src/app/shared/models/Area';
import { ITipoArea } from 'src/app/shared/models/TipoArea';
import { ITipoIndicador } from 'src/app/shared/models/TipoIndicador';
import { INombreConfiguracion } from 'src/app/shared/models/NombreConfiguracion';
import { INombreSoporte } from 'src/app/shared/models/NombreSoporte';
import { INombreTipoServicio } from 'src/app/shared/models/NombreTipoServicio';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToastModule } from 'primeng/toast';
import { tabuladorCostosService } from 'src/app/shared/services/tabuladorCostos.service';
import { ITiposProductos } from 'src/app/shared/models/TiposProductos';
import { IClasificacionPersonal } from 'src/app/shared/models/ClasificacionPersonal';
import { ITipoManiobra } from 'src/app/shared/models/TipoManiobra';
import { ITipoRutaBrief } from 'src/app/shared/models/TipoRutaBrief';
import { ITipoConfirmacion } from 'src/app/shared/models/TipoConfirmacion';
import { IZonaDescarga } from 'src/app/shared/models/ZonaDescarga';
import { ITipoCaja } from 'src/app/shared/models/TipoCaja';
import { IGeneraCarga } from 'src/app/shared/models/GeneraCarga';
import { catMunicipios } from 'src/app/shared/models/catMunicipios';
import ITabuladorCostos from 'src/app/shared/models/TabuladorCostos';
import { ChangeDetectorRef } from '@angular/core';
import ICiudades from 'src/app/shared/models/Ciudades';
import { FormsModule } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-clientes-prospecto',
  templateUrl: './clientes-prospecto.component.html',
  styleUrls: ['./clientes-prospecto.component.scss']
})

export class ClientesProspectoComponent implements OnInit {
  tablaTabuladorCostos: ITabuladorCostos[] = [];
  objCiudad: ICiudades[] = [];
  objdevueltoCiudad: ICiudades[] = [];
  ciudadesselect: ICiudades[] = [];

  cat_TiposProductos: ITiposProductos[] = [];
  nombre_cte_global: string = '';
  ciudadData: { [key: string]: any } = {};
  idx_global:number=0;

  cat_ClasificacionPersonal: IClasificacionPersonal[] = [];
  cat_TipoManiobra: ITipoManiobra[] = [];
  cat_TipoRuta: ITipoRutaBrief[] = [];
  cat_TipoConfirmacion: ITipoConfirmacion[] = [];
  cat_ZonaDescarga: IZonaDescarga[] = [];
  cat_TipoCaja: ITipoCaja[] = [];

  cat_GeneraCarga: IGeneraCarga[] = [];
  dspPropuestaComercial: boolean = false;
  cat_nombresContacto:any[]=[{
    NombreCompletoCto:''
  }];

  uploadedFiles: any[] = [];
  procesosGet: any[] = [];
  uploadedFilesPropuestaName: any[] = [];
  uploadedFilesPropuestaVat: any[] = [];
  uploadedFilesPropuestaZIP: any[] = [];
  uploadedFilesPropuestaID: any[] = [];
  uploadedFilesPropuestaFiscalRegimen: any[] = [];
  arregloCiuades: catMunicipios[] = [];
  uploadedSoporte: any[] = [];
  ArchivosSubidos: any[] = [];
  savedFiles: any[] = [];
  contadorPropuestaAceptada: number = 0;
  SplitValor: any;
  valorNum: number = 0;
  color_gris_deshabilitadas: string = '#D9D9D9';
  dataGlobal: ClientesProspecto[] = [];
  texto_dialog: string = ''
  dspArchivo: boolean = false;
  StrBloqueado: string = '';
  id_global: number = 0;
  StrContacto: string = '';
  lstSoporteCobranza: ISoporteCobranza[] = [{
    id_soportecobranza: 0
  }]

  lstAreas: IArea[] = [{
    id_area: 0
  }]

  catAreas: IArea[] = [{
    id_area: 1,
    nom_area: 'Local'
  },
  {
    id_area: 2,
    nom_area: 'Foranea'
  },
  {
    id_area: 3,
    nom_area: 'Ambas'
  },]

  estatusPropuesta: any[] = [{
    value: 1,
    label: 'SIN RESPUESTA',
    icon: 'fa-regular fa-circle-question',
    bgColor: 'orange'
  },
  {
    value: 2,
    label: 'ACEPTADA',
    icon: 'fa-regular fa-circle-check',
    bgColor: 'green'
  }, {
    value: 3,
    label: 'RECHAZADA',
    icon: 'fa-regular fa-circle-xmark',
    bgColor: 'red'
  }]
  contactoEmpresaObj: ContactoEmpresa[] = [];
  estados: Estados_Ciudades[] = [];
  dspBriefComercial: boolean = false;
  bolComercial: boolean = false;
  dspFrmCotizacion: boolean = false;
  tipoP: any[] = [];
  /*tipoP: any[] = [{
    id_TipoProducto: 1,
    nom_TipoProducto: 'SECO'
  },
  {
    id_TipoProducto: 2,
    nom_TipoProducto: 'FRIO'
  }]
  */
  ManiobrasServicios: any[] = [{
    id_maniobra: 1,
    opcion_maniobra: 'SI'
  },
  {
    id_maniobra: 2,
    opcion_maniobra: 'NO'
  }]
  ciudadesform: FormGroup = new FormGroup({
    ciudad: new FormControl('', []),
    lv: new FormControl(0, []),
    v_3_5: new FormControl(0, []),
    rabon: new FormControl(0, []),
    th: new FormControl(0, []),
    _53FT: new FormControl(0, []),
    usuario: new FormControl('', []),
    celular: new FormControl('', []),
    visible: new FormControl(true)
  })
  mun: string = '';
  contadorIndicadores: number = 0;
  contadorConfiguraciones: number = 0;
  contadorServicios: number = 0;
  contadorTipoProyecto: number = 0;

  //informacionBrief: IInfoBrief[] = [];
  informacionBrief: IInfoBrief = {
    // Inicializamos clientesBrief como un objeto vacío para evitar que sea undefined
    clientesBrief: {
      corporativo: "",
      area_Geografica: 0,
      caracteristicasProyecto: "",
      cliente: 0,
      correo: "",
      fec_inicio: "",
      fec_fin: "",
      fecha: "",
      imp_venta_estimada: 0,
      nombreAplicacion: "",
      num_DiasCredito: 0,
      penalizaciones: "",
      periodoFacturacion: 0,
      proyecto: "",
      urlPortalComercial: "",
      urlPortalOperativo: ""
    },
    // Inicializa otras propiedades de acuerdo a tus necesidades
    tipo_Proyecto: "",
    ciudad: [],
    soporteCobranza: [],
    tipos_Servicio: [],
    config_unidad: [],
    indicador_des: [],
    manualDocumentos: undefined // Puedes usar undefined o inicializar con un valor predeterminado
  };

  isMouseOverDialogr: boolean = false;
  hasMouseEntered: boolean = false;

  catAreaGeografica: any[] = [{
    area: 'Local'
  },
  {
    area: 'Foranea'
  }];

  strMarcadoPor: string | undefined = '';
  strFechaMarcado: string = ''

  dspMarcadoProcesos: boolean = false;
  cat_usuarioscomercial: UsuariosComercial[] = [];

  NombresPDF: any[] = [];
  NombresPDFManuales: any[] = [];

  catSoporteCobranza: ISoporteCobranza[] = [];

  UploadEvent: Iuploadevent[] = [];

  municipios: Estados_Ciudades[] = [];
  contador: number = 0;

  numeroContacto: number = 0;
  idclienteprospecto: number = 0;

  indicadoresDes: IIndicadoresDesempeño[] = [];

  lstIndicadoresDesempeño: IIndicadoresDesempeño[] = [{
    des_indicadordesempenio: ''
  }];
  blockedPanel: boolean = false;

  objFormData: FormData = new FormData();

  fileContent = 'Contenido del archivo';
  fileName = 'archivo.txt';
  options: FilePropertyBag = { type: 'text/plain' };
  selectedFile: File = new File([this.fileContent], this.fileName, this.options);

  ModeloEstatus: IModeloEstatus[] = [];
  Menu: MenuItem[] = [];
  opcionPreseleccionada: string = '';
  usuarioSeleccionados: string = '';
  lstUsuariosTareas: IusuarioTarea[] = [];
  NomEmpresaGlobal: string = '';
  tamanioRetorno: any[] = [];

  checked: boolean = false;
  VerEstatus: boolean = false;
  activeStepIndex: number = 0;

  MovimientosCtes: IMovimientosCteProspecto[] = [];

  Puestos: IPuestos[] = [];
  ClasificacionPuestos: IClasificacionPuestos[] = [];
  archivoUrl: string = '';
  PesoPluma: number = 0;
  //dropdown
  valorMalo: number = 0;
  opcionSeleccionada: any;
  dropdownControl = new FormControl();
  valorCombo: number = 0;
  valorEstatus: number = 0;
  valorEstatusPaso: number = 0;
  campo: string = '';
  campo2:string = '';

  valor: number = 0;
  estadosss: string = '';
  activeIndex: any;

  frecuenciavisita: any[] = [{
    id_visita: 1
  }, {
    id_visita: 2
  }, {
    id_visita: 3
  }]
  regresoTarimas: any[] = [{
    id_regresotarima: 1,
    des_regresotarima: 'SI'
  },
  {
    id_regresotarima: 2,
    des_regresotarima: 'NO'
  }]

  selectedHoraCarga: string = '00:00';
  selectedHoraDescarga: string = '00:00';

  selectedTiempoCarga: string = '00:00';
  selectedTiempoDescarga: string = '00:00';
  selectedTiempoEstadia: string = '00:00';

  estadoMunicipio: IEstadoMunicipio[] = [];

  objetoCtesProspectoPasar: ClientesProspecto[] = [];

  valueEstado: string = '';
  PasoSeleccionado: any;

  objCtoCte: ContactoEmpresa = {
    opcion: 0,
    id_contacto_empresa: 0,
    id_cte_prospecto: 0,
    des_nombre: '',
    des_apellidopaterno: '',
    des_apellidomaterno: '',
    nom_puesto: '',
    des_correocontacto: '',
    num_telefono: '',
    nom_clasificacionpuesto: '',
    id_tipo_clasificacion: 0,
    id_tipo_puesto: 0
  }

  objCteProspecto: ClientesProspecto = {
    des_sectorempresa: '',
    rfc: '',
    opcion: 0,
    des_paginaweb: '',
    nom_razonsocial: '',
    id_cte_prospecto: 0,
    desc_observacion: '',
    fec_registro_resp: '',
    fec_ultimo_movimiento_resp: '',
    fec_ultimo_movimiento: '',
    cod_usuario: '',
    id_medio_de_contacto: 0,
    nom_mediosdecontacto: '',
    id_clasificacion_cte: 0,
    nom_clasificacion: '',
    id_sub_clasificacion_cte: 0,
    nom_subclasificacion: '',
    id_tipo_proyecto: 0,
    nombre_proyecto: '',
    clv_interesado: 0,
    des_interesado: '',
    clv_aceptado: 0,
    clv_tabulador: 0,
    num_folio: '',
    cod_personaseguimiento:'',
    nom_personaseguimiento: '',
    estado: '',
    municipios: '',
    nom_empresa: '',
    des_tipoempresa: '',
    clv_operacion: 0,
    des_estatus: ''
  }
  municipioscat: catMunicipios[] = [];

 
  hora1:string='';
  hora2:string='';
  hora3:string='';

  MarcadoProcesosForm: FormGroup = new FormGroup({
    Capacitacion: new FormControl(false),
    StrUsuarioCapacitacion: new FormControl('', []),
    FechaMarcadoCapacitacion: new FormControl('', []),
    ProcesoOperativo: new FormControl(false),
    StrUsuarioProcesoOperativo: new FormControl('', []),
    FechaMarcadoProcesoOperativo: new FormControl('', []),
    RegistroPAO: new FormControl(false),
    StrUsuarioRegistroPAO: new FormControl('', []),
    FechaMarcadoRegistroPAO: new FormControl('', []),
    RegistroRuta: new FormControl(false),
    StrUsuarioRegistroRuta: new FormControl('', []),
    FechaMarcadoRegistroRuta: new FormControl('', []),
    RegistroTabulador: new FormControl(false),
    StrUsuarioRegistroTabulador: new FormControl('', []),
    FechaMarcadoRegistroTabulador: new FormControl('', []),
    ProcesoFacturacion: new FormControl(false),
    StrUsuarioProcesoFacturacion: new FormControl('', []),
    FechaMarcadoProcesoFacturacion: new FormControl('', [])
  })

  check: boolean = true;
  chec: boolean = true;
  chet: boolean = true;
  AplicacionFlag: boolean = true;

  usuario: User = {};
  clienteProspecto: ClientesProspecto[] = [];
  medioDContacto: MediosDeContacto[] = [];
  clasificacion: Clasificacion[] = [];
  subclasificacion: SubClasificacion[] = [];
  tipoproyectos: TipoProyecto[] = [];
  MunicipiosSeleccionados: string[] = [];

  lstTipoProyectos: TipoProyecto[] = [{
    nombre: ''
  }]

  lstIndicadoresDes: IIndicadoresDesempeño[] = [{
    des_indicadordesempenio: ''
  }]

  estatus: Estatus[] = [{
    clv_activo: 0,
    des_estatus: 'No interesado'
  }, {
    clv_activo: 1,
    des_estatus: 'Interesado'
  }, {
    clv_activo: 2,
    des_estatus: 'Sin Respuesta'
  }];

  dspContactoEmpresa: boolean = false;

  valorSeleccionado: number = 0;

  dspVerContactoEmpresa: boolean = false;

  confUnidades: IConfiguracionUnidades[] = [];

  lstConfUnidades: IConfiguracionUnidades[] = [{
    nombre: ''
  }]

  usuariosSeleccionados: number[] = [];
  tiposProyectoSeleccionados: number[] = [];
  configuracionesSeleccionadas: number[] = [];
  indicadoresSeleccionados: number[] = [];
  areasSeleccionadas: number[] = [];

  @ViewChild('dt1') dt!: Table;
  @ViewChild('dt2') dt2!: Table;
  REGEX_RFC: any = '^[A-Za-z0-9]{12,13}$';

  SubirArchivoForm: FormGroup = new FormGroup({
    CampoArchivo: new FormControl([''], [])
  });

  ClienteProspectoForm: FormGroup = new FormGroup({
    id_cte_prospecto: new FormControl(0, []),
    nombreClienteProspecto: new FormControl('', [
      Validators.required
    ]),
    rfc: new FormControl('', [Validators.required, Validators.pattern(this.REGEX_RFC)]),
    paginaWeb: new FormControl('', [
      Validators.required
    ]),
    razonSocial: new FormControl('', [
      Validators.required
    ]),
    medioDeContacto: new FormControl(0, [
      Validators.required
    ]),
    clasificacionCliente: new FormControl(0, [
      Validators.required
    ]),
    subclasificacionCliente: new FormControl(0, [
      Validators.required
    ]),
    tipoproyecto: new FormControl(0, [
      Validators.required
    ]),
    observacionAcercamiento: new FormControl('', []),
    fec_alta: new FormControl(new Date, []),
    clave_interesado: new FormControl(0, []),
    estado: new FormControl(0, [
      Validators.required
    ]),
    municipio: new FormControl(0, [
      Validators.required
    ]),
    clv_interesado: new FormControl(0, []),
    clv_tabulador: new FormControl(0, []),
    num_folio: new FormControl(0, []),
    cod_personaseguimiento: new FormControl('', [Validators.required]),
    nom_personaseguimiento: new FormControl('', [Validators.required]),

  });

  controlform: FormGroup = new FormGroup({
    chckbox: new FormControl(false)
  })

  contactoEmpresaForm: FormGroup = new FormGroup({
    id_contacto_empresa: new FormControl(0, []),
    id_cte_prospecto: new FormControl(0, []),
    correoContacto: new FormControl('', [Validators.required]),
    nombres: new FormControl('', [Validators.required]),
    aPaterno: new FormControl('', [Validators.required]),
    aMaterno: new FormControl('', []),
    cargo: new FormControl(0, [Validators.required]),
    num_telefono: new FormControl('', [Validators.required]),
    area: new FormControl(0, [Validators.required]),
  })

  BriefForm: FormGroup = new FormGroup({
    nom_Cliente: new FormControl('', []),
    tipoProyecto: new FormControl('', []),
    fecha: new FormControl(Date, []),
    correoContacto: new FormControl('', []),
    corporativo: new FormControl('', []),
    ciudades: new FormControl<catMunicipios[] | null>(null),
    importeVenta: new FormControl(0, []),
    fecha_inicio: new FormControl(Date, []),
    fecha_termino: new FormControl(Date, []),
    periodoFacturacion: new FormControl(0, []),
    soporteCobranza: new FormControl<ISoporteCobranza[] | null>(null),
    num_DiasCredito: new FormControl(0, []),
    caracteristicasProyecto: new FormControl('', []),
    tipos_Servicio: new FormControl<TipoProyecto[] | null>(null),
    configuracion_Unidades: new FormControl<IConfiguracionUnidades[] | null>(null),
    indicadoresDesempeno: new FormControl<IIndicadoresDesempeño[] | null>(null),
    penalizaciones: new FormControl('', []),
    urlPortalComercial: new FormControl('', []),
    urlPortalOperativo: new FormControl('', []),
    manualDocumentos: new FormControl(FormData, []),
    nombreAplicacion: new FormControl('', []),
    area_Geografica: new FormControl(0),
    chek: new FormControl(false),
    chet: new FormControl(false),
    AplicacionFlag: new FormControl(false),
    tipoProducto: new FormControl(0, []),
    pesoMercancia: new FormControl(0, []),
    horario_carga: new FormControl('', []),
    horario_descarga: new FormControl('', []),
    recibe_descarga: new FormControl('', []),
    tiempoCarga: new FormControl('', []),
    tiempoDescarga: new FormControl('', []),
    tiempoEspera_Estadia: new FormControl('', []),
    proceso_descarga: new FormControl('', []),
    genera_carga: new FormControl(0, []),
    lugar_carga: new FormControl(0, []),
    tipo_caja: new FormControl(0, []),
    regreso_tarimas: new FormControl(0, []),
    frecuencia_visita: new FormControl(0, []),
    realiza_descarga: new FormControl(0, []),
    tipo_maniobradescarga: new FormControl(0, []),
    tipo_maniobracarga: new FormControl(0, []),
    tipo_ruta: new FormControl(0, []),
    tipo_confirmacion: new FormControl(0, []),
    num_choferes: new FormControl(0),
    num_auxiliares: new FormControl(0),
    horas_servicio: new FormControl(0, []),
    clasificacion_producto: new FormControl(0, []),
    des_comentariosproyecto: new FormControl('', []),
    des_mercancia: new FormControl('', []),
    des_tamaño: new FormControl('', []),
    id_lugar_descarga: new FormControl(0, []),
    id_maniobrasyserviciosadicionales: new FormControl(0, [])
  })

  lstMunicipios: catMunicipios[] = [];
  lstNombreArea: ITipoArea[] = [];
  lstTipoIndicador: ITipoIndicador[] = [];
  lstNombreConfiguracion: INombreConfiguracion[] = [];
  lstNombreSoporte: INombreSoporte[] = [];
  lstNombreTipoServicio: INombreTipoServicio[] = [];

  otroMenu: any[] = [];
  isMouseOverDialog: boolean = false;
  items: MenuItem[] = [];
  numero_operacion: number = 0;
  texto_operacion: string = '';
  resultado: IResultadoGeneral = {
    clv_estatus: 0,
    messageDetails: ''
  };
  loading: boolean = true;
  dspNuevoCliente: boolean = false;
  fecha_dia: any;
  activityValues: number[] = [0, 100];
  selectedValue: number = 0;
  selectedvaluestring: string = '';

  data_editar_Brief: IInfoBrief = {
    clientesBrief: {
      corporativo: '',
      area_Geografica: 0,
      caracteristicasProyecto: '',
      cliente: 0,
      correo: '',
      fec_inicio: '',
      fec_fin: '',
      fecha: '',
      imp_venta_estimada: 0,
      nombreAplicacion: '',
      num_DiasCredito: 0,
      penalizaciones: '',
      periodoFacturacion: 0,
      proyecto: '',
      urlPortalComercial: '',
      urlPortalOperativo: '',
    },
    tipo_Proyecto: '',

    ciudad: [],
    soporteCobranza: [],
    tipos_Servicio: [],
    config_unidad: [],
    indicador_des: [],
    manualDocumentos: new FormData()
  }

  data_editar_clienteProspecto: ClientesProspecto = {
    des_sectorempresa: '',
    rfc: '',
    opcion: 0,
    des_paginaweb: '',
    nom_razonsocial: '',
    id_cte_prospecto: 0,
    desc_observacion: '',
    fec_registro_resp: '',
    fec_ultimo_movimiento_resp: '',
    fec_ultimo_movimiento: '',
    cod_usuario: '',
    id_medio_de_contacto: 0,
    nom_mediosdecontacto: '',
    id_clasificacion_cte: 0,
    nom_clasificacion: '',
    id_sub_clasificacion_cte: 0,
    nom_subclasificacion: '',
    id_tipo_proyecto: 0,
    nombre_proyecto: '',
    clv_interesado: 0,
    des_interesado: '',
    clv_aceptado: 0,
    clv_tabulador: 0,
    num_folio: '',
    cod_personaseguimiento:'',
    nom_personaseguimiento: '',
    estado: '',
    municipios: '',
    nom_empresa: '',
    des_tipoempresa: '',
    clv_operacion: 0,
    des_estatus: ''
  };

  documents = [
    { name: 'Name', label: 'Name', uploaded: false, isPdf: false, files: [] as File[], idx: 0 },
    { name: 'Vat', label: 'VAT', uploaded: false, isPdf: false, files: [] as File[], idx: 0 },
    { name: 'Zip', label: 'ZIP', uploaded: false, isPdf: false, files: [] as File[], idx: 0 },
    { name: 'Id', label: 'ID', uploaded: false, isPdf: false, files: [] as File[], idx: 0 },
    { name: 'RegimenFiscal', label: 'Fiscal Regime', uploaded: false, isPdf: false, files: [] as File[], idx: 0 }
  ];
  documentsDevuelto = [
    { name: 'Name', label: 'Name', uploaded: false, isPdf: false, files: [] as File[], idx: 0 },
    { name: 'Vat', label: 'VAT', uploaded: false, isPdf: false, files: [] as File[], idx: 0 },
    { name: 'Zip', label: 'ZIP', uploaded: false, isPdf: false, files: [] as File[], idx: 0 },
    { name: 'ID', label: 'ID', uploaded: false, isPdf: false, files: [] as File[], idx: 0 },
    { name: 'RegimenFiscal', label: 'Fiscal Regime', uploaded: false, isPdf: false, files: [] as File[], idx: 0 }
  ];

  form!: FormGroup;
  ciudadesForms: FormGroup[] = [];
  //objdevueltoCiudads: any[] = [];
  catMercancia: any[] = [];
  catMercanciaUnica: any[] = [];

  desgloseMercancia: any[] = [{
    des_mercancia: ''
  }];
  mercanciaSelect: string = '';

  constructor(private httpService: HttpService, private fb: FormBuilder, private ClasificacionService: clasificacion, private SubClasificacionService: subclasificacion, private MedioDeContactoService: mediodecontacto,
    private ClienteProspectoService: clienteprospecto, private confirmationService: ConfirmationService, private message: MessageService, private auth: AuthService,
    private tipoproyectoservice: tipoProyecto, private permisos: UsuariosService, private calendarioServicio: calendarioService, private route: ActivatedRoute,
    private router: Router, private tabuladorservice: tabuladorCostosService,private primengConfig: PrimeNGConfig, private cdr: ChangeDetectorRef) {
    this.cargarInformacionUsuario();
  }

  ngOnInit(): void {
    this.getListadoClientesProspecto();
    this.getListadoClasificacion();
    this.getListadoMediosContacto();
    this.getListadoProyectos();
    this.getListadoSubClasificacion();
    this.getListadoEstados();
    this.obtenerUsuariosComercial();
    this.ObtenerPuestos();
    this.ObtenerClasificacionPuestos();
    this.ObtenerCatSoporteCobranza();
    this.ObtenerConfiguracion();
    this.ObtenerIndicadores();
    this.ObtenerMunicipios();
    this.ObtenerTiposProductos();
    this.obtenerTipoCaja();
    this.obtenerClasificacionPersonal();
    this.obtenerTipoRuta();
    this.obtenerZonaDescarga();
    this.obtenerTipoConfirmacion();
    this.ObtenerCarga();
    this.obtenerTipoManiobra();
    this.getMercancia();
    this.obtenerTipoP();
    this.obtenerModeloEstatus();
    this.primengConfig.setTranslation({
      // Configuración en español
      startsWith: 'Empieza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Termina con',
      equals: 'Igual a',
      notEquals: 'Diferente de',
      // Para los filtros de fecha
      dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Limpiar',
      // Otros filtros numéricos y generales
      lt: 'Menor que',
      lte: 'Menor o igual que',
      gt: 'Mayor que',
      gte: 'Mayor o igual que',
      is: 'Es',
      isNot: 'No es',
      before: 'Antes',
      after: 'Después',
      apply: 'Aplicar',
      matchAll: 'Coincidir con todo',
      matchAny: 'Coincidir con cualquiera',
      addRule: 'Agregar regla',
      removeRule: 'Eliminar regla',
      accept: 'Sí',
      reject: 'No',
    });
  }

  obtenerTipoP() {
    this.ClienteProspectoService.GetTipoProducto().subscribe((resp) => {
      this.tipoP = resp;
      setTimeout(() => {
      }, 150);
    })
  }

  CambiarMarcado() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let fecha = dateFormat;
    //Formatear fecha de Inicio
    if (this.MarcadoProcesosForm.get('Capacitacion')?.value === true) {
      this.MarcadoProcesosForm.controls['StrUsuarioCapacitacion'].setValue(this.usuario.name);
      this.MarcadoProcesosForm.controls['StrUsuarioCapacitacion'].disable();
      this.MarcadoProcesosForm.controls['FechaMarcadoCapacitacion'].setValue(fecha);
      this.MarcadoProcesosForm.controls['FechaMarcadoCapacitacion'].disable();
      let datos_enviar = {
        id_clave: 1,
        nom_usuario: this.usuario.name,
        id_cte_prospecto: this.id_global,
        nom_clave: 'clv_capacitacion',
        idx_tabulador:this.idx_global
      }
      this.ClienteProspectoService.MarcarProceso(datos_enviar).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.verPropuestaComercialConID(this.id_global);
          this.tablaTabuladorCostos=[...this.tablaTabuladorCostos];
          
        }, 100);
      });
    }
    if (this.MarcadoProcesosForm.get('ProcesoOperativo')?.value === true) {
      this.MarcadoProcesosForm.controls['StrUsuarioProcesoOperativo'].setValue(this.usuario.name);
      this.MarcadoProcesosForm.controls['StrUsuarioProcesoOperativo'].disable();
      this.MarcadoProcesosForm.controls['FechaMarcadoProcesoOperativo'].setValue(fecha);
      this.MarcadoProcesosForm.controls['FechaMarcadoProcesoOperativo'].disable();
      let datos_enviar = {
        id_clave: 1,
        nom_usuario: this.usuario.name,
        id_cte_prospecto: this.id_global,
        nom_clave: 'clv_procesooperativo',
        idx_tabulador:this.idx_global
      }
      this.ClienteProspectoService.MarcarProceso(datos_enviar).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.verPropuestaComercialConID(this.id_global);
          this.tablaTabuladorCostos=[...this.tablaTabuladorCostos];
          
        }, 100);
      });
    }
    if (this.MarcadoProcesosForm.get('RegistroPAO')?.value === true) {
      this.MarcadoProcesosForm.controls['StrUsuarioRegistroPAO'].setValue(this.usuario.name);
      this.MarcadoProcesosForm.controls['StrUsuarioRegistroPAO'].disable();
      this.MarcadoProcesosForm.controls['FechaMarcadoRegistroPAO'].setValue(fecha);
      this.MarcadoProcesosForm.controls['FechaMarcadoRegistroPAO'].disable();
      let datos_enviar = {
        id_clave: 1,
        nom_usuario: this.usuario.name,
        id_cte_prospecto: this.id_global,
        nom_clave: 'clv_registropao',
        idx_tabulador:this.idx_global
      }
      this.ClienteProspectoService.MarcarProceso(datos_enviar).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.verPropuestaComercialConID(this.id_global);
          this.tablaTabuladorCostos=[...this.tablaTabuladorCostos];
          
        }, 100);
      });
    }
    if (this.MarcadoProcesosForm.get('RegistroRuta')?.value === true) {
      this.MarcadoProcesosForm.controls['StrUsuarioRegistroRuta'].setValue(this.usuario.name);
      this.MarcadoProcesosForm.controls['StrUsuarioRegistroRuta'].disable();
      this.MarcadoProcesosForm.controls['FechaMarcadoRegistroRuta'].setValue(fecha);
      this.MarcadoProcesosForm.controls['FechaMarcadoRegistroRuta'].disable();
      let datos_enviar = {
        id_clave: 1,
        nom_usuario: this.usuario.name,
        id_cte_prospecto: this.id_global,
        nom_clave: 'clv_registroderutas',
        idx_tabulador:this.idx_global
      }
      this.ClienteProspectoService.MarcarProceso(datos_enviar).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.verPropuestaComercialConID(this.id_global);
          this.tablaTabuladorCostos=[...this.tablaTabuladorCostos];
          
        }, 100);
      });
    }
    if (this.MarcadoProcesosForm.get('RegistroTabulador')?.value === true) {
      this.MarcadoProcesosForm.controls['StrUsuarioRegistroTabulador'].setValue(this.usuario.name);
      this.MarcadoProcesosForm.controls['StrUsuarioRegistroTabulador'].disable();
      this.MarcadoProcesosForm.controls['FechaMarcadoRegistroTabulador'].setValue(fecha);
      this.MarcadoProcesosForm.controls['FechaMarcadoRegistroTabulador'].disable();
      let datos_enviar = {
        id_clave: 1,
        nom_usuario: this.usuario.name,
        id_cte_prospecto: this.id_global,
        nom_clave: 'clv_registrodetabuladores',
        idx_tabulador:this.idx_global
      }
      this.ClienteProspectoService.MarcarProceso(datos_enviar).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.verPropuestaComercialConID(this.id_global);
          this.tablaTabuladorCostos=[...this.tablaTabuladorCostos];
          
        }, 100);
      });
    }
    if (this.MarcadoProcesosForm.get('ProcesoFacturacion')?.value === true) {
      this.MarcadoProcesosForm.controls['StrUsuarioProcesoFacturacion'].setValue(this.usuario.name);
      this.MarcadoProcesosForm.controls['StrUsuarioProcesoFacturacion'].disable();
      this.MarcadoProcesosForm.controls['FechaMarcadoProcesoFacturacion'].setValue(fecha);
      this.MarcadoProcesosForm.controls['FechaMarcadoProcesoFacturacion'].disable();
      let datos_enviar = {
        id_clave: 1,
        nom_usuario: this.usuario.name,
        id_cte_prospecto: this.id_global,
        nom_clave: 'clv_procesodefacturacion',
        idx_tabulador:this.idx_global
      }
      this.ClienteProspectoService.MarcarProceso(datos_enviar).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.verPropuestaComercialConID(this.id_global);
          this.tablaTabuladorCostos=[...this.tablaTabuladorCostos];
          
        }, 100);
      });
    }
  }
  CerrarMarcado() {
    this.MarcadoProcesosForm.reset();
    this.procesosGet=[];
    this.dspMarcadoProcesos = false;
  }

  obtenerValoresUnicos(array: any[], key: string): any[] {
    const set = new Set(array.map(item => item[key]));
    return Array.from(set);
  }

  getMercancia() {
    this.ClienteProspectoService.GetMercancia().subscribe((resp) => {
      this.catMercancia = resp;
      this.catMercanciaUnica = this.obtenerValoresUnicos(resp, 'des_mercancia');
      this.desgloseMercancia = this.catMercanciaUnica.map((valor: string) => ({
        des_mercancia: valor
      }));
      setTimeout(() => {
      }, 200);
    })
  }
  MercanciaChange() {
    this.tamanioRetorno = this.catMercancia.filter(obj => obj.des_mercancia === this.mercanciaSelect);
    if (this.mercanciaSelect === 'Sobre') {
      this.tamanioRetorno[0].des_tamaño = 'X';
      this.BriefForm.controls['des_tamaño'].setValue('X');
    }
  }
  onMunicipiosChanged() {
    this.ciudadesform.controls['ciudad'].setValue(this.BriefForm.get('ciudades')?.value);
    this.ciudadesform.controls['usuario'].setValue(this.usuario.name);
    this.ciudadesform.controls['celular'].setValue(this.contactoEmpresaObj[0].num_telefono);
  }

  updateFormValues() {
    this.MunicipiosSeleccionados.forEach((municipio, index) => {
      const data = this.objdevueltoCiudad.find(obj => obj.municipio === municipio);
      if (data) {
        this.ciudadesForms[index].patchValue(data);

      }
    });
  }
  CerrarFrmCotizacion() {
    this.dspFrmCotizacion = false;
  }

  addMunicipioForm(data: FormGroup, index: number) {
    

    this.ciudadesForms.splice(index, 1);
    this.objCiudad.push({
      municipio: data.value.municipio,
      lv: data.value.lv,
      v_3_5: data.value.v_3_5,
      rabon: data.value.rabon,
      th: data.value.th,
      _53FT: data.value._53FT,
      usuario: data.value.usuario,
      celular: data.value.celular
    });
    
    this.message.clear();
    this.message.add({ severity: 'success', summary: 'Importe Agregado', detail: 'Importe Agregado Correctamente' });
  }

  removeMunicipioForm(index: number) {
    this.ciudadesForms.splice(index, 1);
    this.MunicipiosSeleccionados.splice(index, 1);
    this.MunicipiosSeleccionados = [...this.MunicipiosSeleccionados];
    this.cdr.detectChanges();
  }

  createMunicipioFormGroup(data: any): FormGroup {
    return this.fb.group({
      municipio: [data.municipio || ''],
      lv: [data.lv || ''],
      v_3_5: [data.v_3_5 || ''],
      rabon: [data.rabon || ''],
      th: [data.th || ''],
      _53FT: [data._53FT || ''],
      usuario: [data.usuario || this.usuario.name],
      celular: [data.celular || '', Validators.required]
    });
  }

  populateMunicipiosForms(dataArray: any[]) {
    this.ciudadesForms = dataArray.map(data => this.createMunicipioFormGroup(data));
  }

  onMunicipiosChange() {
    this.ciudadesForms = this.MunicipiosSeleccionados.map(municipio => this.createMunicipioFormGroup({ municipio }));
    this.updateFormValues();
  }

  onEstatusPropuestaChange(event: any, data: any) {
    let valor = event.value;
    let obj = this.estatusPropuesta.find(obj => obj.value === valor);
    let Nomb: string = '';
    if (obj) {
      if (obj.label) {
        this.confirmationService.confirm({
          message: 'Estas Seguro que Deseas Cambiar el estatus de esta Propuesta a <b>' + obj.label + '</b>?',
          header: 'Confirmar, Una vez que se Modifique el Estatus a ' + obj.label + ' no se podra Volver a Modificar',
          icon: 'pi pi-check-square',
          accept: () => {
            let data_enviar = {
              idx: data.idx,
              clv_aceptado: this.valorNum
            }
            this.tabuladorservice.updatePropuestaComercial(data_enviar).subscribe((resp) => {
              var respuesta = resp;
              setTimeout(() => {
                this.verPropuestaComercialConID(this.id_global);
                this.message.add({ key: 'msj', severity: 'success', summary: 'Se ha Modificado el Estatus', detail: 'Estatus Modificado con Exito' });
              }, 400);
            })
          },
          reject: (type: any) => {
            switch (type) {
              case ConfirmEventType.REJECT:
                this.valorNum = data.clv_aceptado;
                this.tablaTabuladorCostos = [...this.tablaTabuladorCostos];
                this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
                break;
              case ConfirmEventType.CANCEL:
                this.valorNum = data.clv_aceptado;
                this.tablaTabuladorCostos = [...this.tablaTabuladorCostos];
                this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
                break;
            }
          }
        })
      }
    }
  }

  ObtenerCarga() {
    this.ClienteProspectoService.GetGeneraCarga().subscribe((resp) => {
      this.cat_GeneraCarga = resp;
      setTimeout(() => {
      }, 150);
    })
  }
  ObtenerTiposProductos() {
    this.ClienteProspectoService.GetTiposProductos().subscribe((resp) => {
      this.cat_TiposProductos = resp;
      setTimeout(() => {
      }, 150);
    })
  }
  verPropuestaComercialConID(id: number) {
    this.tabuladorservice.getTabuladorPrincipal(id).subscribe((resp) => {
      this.tablaTabuladorCostos = resp.filter(res => res.clv_enviado === 1);
      setTimeout(() => {
      }, 150);
    })
  }
  SubirArchivoPropuesta(event: any, doc: any) {
    doc.uploaded = true;
    // Handle file upload logic

    if (doc.label === 'Name') {
      const formDataName = new FormData();
      for (let file of event.files) {
        formDataName.append('files', file);
      }
      let datos = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
        numero: 1
      }
      let data_env = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
      }
      this.ClienteProspectoService.SaveArchivoPropuesta(formDataName, datos).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.GetArchivos(data_env);
          this.message.add({ key: 'msj', severity: 'success', summary: 'Archivo Subido con Exito', detail: 'Operacion Realizada con Exito' });
        }, 400);
      })
    } else if (doc.label === 'VAT') {
      const formDataName = new FormData();
      for (let file of event.files) {
        formDataName.append('files', file);
      }
      let datos = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
        numero: 2
      }
      let data_env = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
      }
      this.ClienteProspectoService.SaveArchivoPropuesta(formDataName, datos).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.GetArchivos(data_env);
          this.message.add({ key: 'msj', severity: 'success', summary: 'Archivo Subido con Exito', detail: 'Operacion Realizada con Exito' });
        }, 400);
      })
    } else if (doc.label === 'ZIP') {
      const formDataName = new FormData();
      for (let file of event.files) {
        formDataName.append('files', file);
      }
      let datos = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
        numero: 3
      }
      let data_env = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
      }
      this.ClienteProspectoService.SaveArchivoPropuesta(formDataName, datos).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.GetArchivos(data_env);
          this.message.add({ key: 'msj', severity: 'success', summary: 'Archivo Subido con Exito', detail: 'Operacion Realizada con Exito' });
        }, 400);
      })
    } else if (doc.label === 'ID') {
      const formDataName = new FormData();
      for (let file of event.files) {
        formDataName.append('files', file);
      }
      let datos = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
        numero: 4
      }
      let data_env = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
      }
      this.ClienteProspectoService.SaveArchivoPropuesta(formDataName, datos).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.GetArchivos(data_env);
          this.message.add({ key: 'msj', severity: 'success', summary: 'Archivo Subido con Exito', detail: 'Operacion Realizada con Exito' });
        }, 400);
      })
    } else if (doc.label === 'Fiscal Regime') {
      const formDataName = new FormData();
      for (let file of event.files) {
        formDataName.append('files', file);
      }
      let datos = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
        numero: 5
      }
      let data_env = {
        id_cte_prospecto: this.id_global,
        nom_empresa: this.nombre_cte_global,
      }
      this.ClienteProspectoService.SaveArchivoPropuesta(formDataName, datos).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.GetArchivos(data_env);
          this.message.add({ key: 'msj', severity: 'success', summary: 'Archivo Subido con Exito', detail: 'Operacion Realizada con Exito' });
        }, 400);
      })
    }
  }

  onClear(doc: any) {
    doc.uploaded = false;
    doc.isPdf = false;
  }

  onFileSelect(event: any, doc: any) {
    const file = event.files[0];
    if (file && file.type === 'application/pdf') {
      doc.isPdf = true;
    } else {
      doc.isPdf = false;
    }
  }

  deleteFile(doc: any) {
    this.confirmationService.confirm({
      message: 'Esta seguro que desea Eliminar el Archivo <b>' + doc.name + '?',
      header: 'Confirmacion de Eliminacion',
      icon: 'pi pi-info-circle',
      accept: () => {
        let datos = {
          nombreArchivo: doc.name,
          nom_empresa: this.nombre_cte_global,
          idx: doc.idx,
          num_operacion: 2
        }
        this.ClienteProspectoService.DeleteArchivo(datos).subscribe((resp) => {
          setTimeout(() => {
            doc.uploaded = false;
            this.message.add({ key: 'msj', severity: 'success', summary: 'Hecho', detail: 'Se ha Eliminado el Archivo Exitosamente' });
          }, 500);
        })
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    })
  }
  /*//const selectedFile: File = file.files[0];
    this.confirmationService.confirm({
      message: 'Esta seguro que desea Eliminar el Archivo <b>' + data.nom_archivo + '?',
      header: 'Confirmacion de Eliminacion',
      icon: 'pi pi-info-circle',
      accept: () => {
        let datos = {
          nombreArchivo: data.nom_archivo,
          nom_empresa: this.NomEmpresaGlobal,
          idx: data.idx
        }
        this.ClienteProspectoService.DeleteArchivo(datos).subscribe((resp) => {
          setTimeout(() => {
            this.ObtenerInformacionBrief();
            this.ObtenerNombres(this.idclienteprospecto);
            this.message.add({ key: 'msj', severity: 'success', summary: 'Hecho', detail: 'Se ha Eliminado el Archivo Exitosamente' });
          }, 500);
        })
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    }); */
  downloadFile(doc: any) {
    if (doc.label === 'Name') {
      let datos = {
        nombreArchivo: doc.name,
        nom_empresa: this.nombre_cte_global
      }
      this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe((archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', doc.name); // El nombre del archivo se mantiene igual

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
        error => {
          console.error('Error al obtener archivo:', error);
        });
    } else if (doc.label === 'VAT') {
      let datos = {
        nombreArchivo: doc.name,
        nom_empresa: this.nombre_cte_global
      }
      this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe((archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', doc.name); // El nombre del archivo se mantiene igual

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
        error => {
          console.error('Error al obtener archivo:', error);
        });
    } else if (doc.label === 'ZIP') {
      let datos = {
        nombreArchivo: doc.name,
        nom_empresa: this.nombre_cte_global
      }
      this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe((archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', doc.name); // El nombre del archivo se mantiene igual

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
        error => {
          console.error('Error al obtener archivo:', error);
        });
    } else if (doc.label === 'ID') {
      let datos = {
        nombreArchivo: doc.name,
        nom_empresa: this.nombre_cte_global
      }
      this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe((archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', doc.name); // El nombre del archivo se mantiene igual

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
        error => {
          console.error('Error al obtener archivo:', error);
        });
    } else if (doc.label === 'Fiscal Regime') {
      let datos = {
        nombreArchivo: doc.name,
        nom_empresa: this.nombre_cte_global
      }
      this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe((archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', doc.name); // El nombre del archivo se mantiene igual

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
        error => {
          console.error('Error al obtener archivo:', error);
        });
    }
  }

  viewFile(doc: any) {
    let datos = {
      nombreArchivo: doc.name,
      nom_empresa: this.nombre_cte_global
    }
    this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe(
      (archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();

        link.remove();
      },
      error => {
        console.error('Error al obtener archivo:', error);
      }
    );
  }

  GetArchivos(data: any) {
    let objeto_enviar = {
      id_cte_prospecto: data.id_cte_prospecto,
      nom_empresa: data.nom_empresa
    }
    this.ClienteProspectoService.GetArchivosPropuesta(objeto_enviar).subscribe((resp) => {
      const archivosPropuesta = resp.map(item => {
        if (typeof item === 'string') {
          return item;
        } else if (typeof item === 'object' && item.nom_archivo) { // Suponiendo que cada objeto tiene una propiedad 'nombre'
          return item.nom_archivo;
        }
        return '';
      }).filter(item => item); // Filtramos cualquier cadena vacía resultante

      // Recorremos los archivos de propuesta y actualizamos los documentos correspondientes
      archivosPropuesta.forEach(nom_archivo => {
        // Verificamos que nombreArchivo sea una cadena válida antes de proceder
        if (typeof nom_archivo === 'string' && nom_archivo.trim() !== '') {
          // Buscar el documento en documentsDevuelto que contenga la palabra del archivo usando includes
          const documento = this.documents.find(doc => nom_archivo.includes(doc.name));

          if (documento) {
            // Actualizamos las propiedades del documento
            documento.uploaded = true;
            let obj = resp.filter(ojb => ojb.nom_archivo === nom_archivo);
            documento.name = nom_archivo;
            documento.idx = obj[0].idx;
          }
        }
      });
      setTimeout(() => {
      }, 150);
    });
  }
  CierreRuta(data:any){
    this.confirmationService.confirm({
      message: 'Esta Seguro que Desea Finalizar esta Ruta?',
      header: 'Confirmacion de Cierre de Ruta',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
         let idx=data.idx;
        this.ClienteProspectoService.UpdateCierreRuta(idx).subscribe((resp) => {
          setTimeout(() => {
            this.verPropuestaComercialConID(data.id_cte_prospecto);
            this.message.add({ severity: 'success', summary: 'Se ha Finalizado Correctamente', detail: 'Ruta Finalizada' });
          }, 300);
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    })
  }

  verPropuestaComercial(data: ClientesProspecto) {
    this.documents = this.documentsDevuelto;
    this.tabuladorservice.getTabuladorPrincipal(data.id_cte_prospecto).pipe(
      switchMap(respSaveInfo => {
        var resultado = respSaveInfo;
        return new Observable(observer => {
          let objeto_enviar = {
            id_cte_prospecto: data.id_cte_prospecto,
            nom_empresa: data.nom_empresa
          }
          this.ClienteProspectoService.GetArchivosPropuesta(objeto_enviar).subscribe((resp) => {

            const archivosPropuesta = resp.map(item => {
              if (typeof item === 'string') {
                return item;
              } else if (typeof item === 'object' && item.nom_archivo) { // Suponiendo que cada objeto tiene una propiedad 'nombre'
                return item.nom_archivo;
              }
              return '';
            }).filter(item => item); // Filtramos cualquier cadena vacía resultante

            // Recorremos los archivos de propuesta y actualizamos los documentos correspondientes
            archivosPropuesta.forEach(nom_archivo => {
              // Verificamos que nombreArchivo sea una cadena válida antes de proceder
              if (typeof nom_archivo === 'string' && nom_archivo.trim() !== '') {
                // Buscar el documento en documentsDevuelto que contenga la palabra del archivo usando includes
                const documento = this.documents.find(doc => nom_archivo.includes(doc.name));

                if (documento) {
                  // Actualizamos las propiedades del documento
                  documento.uploaded = true;
                  let obj = resp.filter(ojb => ojb.nom_archivo === nom_archivo);
                  documento.name = nom_archivo;
                  documento.idx = obj[0].idx;
                }
              }
            });
            setTimeout(() => {
              observer.next();
              observer.complete();
            }, 150);
          });
        })
      })
    ).subscribe(() => {
      this.tabuladorservice.getTabuladorPrincipal(data.id_cte_prospecto).subscribe((resp) => {
        this.id_global = data.id_cte_prospecto;
        this.nombre_cte_global = data.nom_empresa;
        this.dspPropuestaComercial = true;
        this.tablaTabuladorCostos = resp.filter(res => res.clv_enviado === 1);
        const obj: ITabuladorCostos[] = [];
        this.bolComercial = this.tablaTabuladorCostos.find(res => res.clv_aceptado === 2) ? true : false;
        this.bolComercial = data.clv_desactivado === 1 ? true : false;
        this.blockedPanel = data.clv_desactivado === 1 ? false : true;
        this.StrBloqueado = data.clv_desactivado === 1 ? 'Marcar como Cliente a Futuro' : 'Desmarcar a Este Cliente de Cliente a Futuro';
        if (this.blockedPanel === true) {
          this.controlform.controls['chckbox'].setValue(false);
        } else {
          this.controlform.controls['chckbox'].setValue(false);
        }
        this.metodo();
        this.cdr.detectChanges();
        setTimeout(() => {
        }, 350);
      })
    })
  }

  /*verPropuestaComercial(data: ClientesProspecto) {
    this.tabuladorservice.getTabuladorPrincipal(data.id_cte_prospecto).subscribe((resp) => {
      this.id_global = data.id_cte_prospecto;
      this.nombre_cte_global = data.nom_empresa;
      this.dspPropuestaComercial = true;

      this.tablaTabuladorCostos = resp.filter(res => res.clv_enviado === 1);
      const obj: ITabuladorCostos[] = [];
      this.bolComercial = this.tablaTabuladorCostos.find(res => res.clv_aceptado === 2) ? true : false;
      this.blockedPanel = data.clv_desactivado === 1 ? false : true;
      this.StrBloqueado = data.clv_desactivado === 1 ? 'Marcar como Cliente a Futuro' : 'Desmarcar a Este Cliente de Cliente a Futuro'
      if (this.blockedPanel === true) {
        this.controlform.controls['chckbox'].setValue(false);
      } else {
        this.controlform.controls['chckbox'].setValue(false);
      }
      this.metodo();
      this.cdr.detectChanges();
      setTimeout(() => {
      }, 350);
    })
  }*/
  /*this.ClienteProspectoService.saveInformacionBrief(this.idclienteprospecto, this.NomEmpresaGlobal, data_enviar).pipe(
            switchMap(respSaveInfo => {
              // Primera llamada HTTP para guardar la información del Brief
              var resultado = respSaveInfo;


              // Se ejecuta la segunda llamada HTTP para editar el cliente prospecto
              this.objetoCtesProspectoPasar[0].opcion = 2;
              this.objetoCtesProspectoPasar[0].flag_tabulador = 1;
              return this.ClienteProspectoService.editClienteProspecto(this.objetoCtesProspectoPasar[0]).pipe(
                switchMap(respEdit => {
                  // Se ejecuta la tercera llamada HTTP para guardar los archivos
                  return new Observable(observer => {
                    let datos = {
                      id_cte_prospecto: this.idclienteprospecto,
                      nom_empresa: this.NomEmpresaGlobal
                    };
                    if (this.numero_operacion === 1) {
                      this.ClienteProspectoService.SaveArchivoBrief(this.objFormData, datos).subscribe(respSaveFiles => {
                        observer.next();
                        observer.complete();
                      });
                    } else if (this.numero_operacion === 2 && this.contador === this.objFormData.append.length) {
                      observer.next();
                      observer.complete();
                    }
                    else if (this.numero_operacion === 2 && this.objFormData !== null) {
                      this.ClienteProspectoService.SaveArchivoBrief(this.objFormData, datos).subscribe(respSaveFiles => {
                        observer.next();
                        observer.complete();
                      });
                    }
                  });
                })
              );
            })
          ).subscribe(() => {
            // Operaciones adicionales después de completar todas las llamadas HTTP
            this.ObtenerInformacionBrief();
            this.uploadedSoporte = [];
            this.message.add({ severity: 'success', summary: 'Informacion de Brief', detail: 'La Informacion se Almaceno de Forma Correcta' });
            //this.cerrarEstatus();
          }); */

  verhora() {


  }
  metodo() {
    if (this.hasMouseEntered) {
      return;
    }

    this.hasMouseEntered = true;
    this.isMouseOverDialog = true;

    if (this.blockedPanel === true) {
      this.blockedPanel = false;

      //this.tablaTabuladorCostos = [...this.tablaTabuladorCostos];


      setTimeout(() => {
        this.blockedPanel = true;
      }, 20);
    }
  }

  obtenerTipoManiobra() {
    this.ClienteProspectoService.GetTipoManiobra().subscribe((resp) => {
      this.cat_TipoManiobra = resp;
      setTimeout(() => {

      }, 150);
    })
  }

  obtenerClasificacionPersonal() {
    this.ClienteProspectoService.GetClasificacionPersonal().subscribe((resp) => {
      this.cat_ClasificacionPersonal = resp;
      setTimeout(() => {

      }, 150);
    })
  }
  obtenerTipoRuta() {
    this.ClienteProspectoService.GetTipoRuta().subscribe((resp) => {
      this.cat_TipoRuta = resp;
      setTimeout(() => {

      }, 150);
    })
  }
  obtenerZonaDescarga() {
    this.ClienteProspectoService.GetZonaDescarga().subscribe((resp) => {
      this.cat_ZonaDescarga = resp;
      setTimeout(() => {

      }, 150);
    })
  }
  obtenerTipoConfirmacion() {
    this.ClienteProspectoService.GetTipoConfirmacion().subscribe((resp) => {
      this.cat_TipoConfirmacion = resp;
      setTimeout(() => {

      }, 150);
    })
  }
  obtenerTipoCaja() {
    this.ClienteProspectoService.GetTipoCaja().subscribe((resp) => {
      this.cat_TipoCaja = resp;
      setTimeout(() => {
      }, 150);
    })
  }


  ObtenerMunicipios() {
    this.tabuladorservice.getMunicipios().subscribe((resp) => {
      this.municipioscat = resp;

      setTimeout(() => {
      }, 400);
    })
  }

  AbrirProcesos(data:any){
    this.ProcesosRuta(data.idx);
    this.idx_global=data.idx;
    this.dspMarcadoProcesos = true;
  }

  ProcesosRuta(idx:number) {
    this.ClienteProspectoService.GetProcesos(this.id_global,idx).subscribe((resp) => {
      this.procesosGet = resp;
      setTimeout(() => {
      if (this.procesosGet[0].clv_capacitacion === 1) {
        this.MarcadoProcesosForm.controls['Capacitacion'].setValue(true);
        this.MarcadoProcesosForm.controls['StrUsuarioCapacitacion'].setValue(this.procesosGet[0].nom_usuarioregistro_capacitacion);
        this.MarcadoProcesosForm.controls['StrUsuarioCapacitacion'].disable();
        this.MarcadoProcesosForm.controls['FechaMarcadoCapacitacion'].setValue(this.procesosGet[0].fec_registro_capacitacion);
        this.MarcadoProcesosForm.controls['FechaMarcadoCapacitacion'].disable();
      }
      if (this.procesosGet[0].clv_procesooperativo === 1) {
        this.MarcadoProcesosForm.controls['ProcesoOperativo'].setValue(true);
        this.MarcadoProcesosForm.controls['StrUsuarioProcesoOperativo'].setValue(this.procesosGet[0].nom_usuarioregistro_procesooperativo);
        this.MarcadoProcesosForm.controls['StrUsuarioProcesoOperativo'].disable();
        this.MarcadoProcesosForm.controls['FechaMarcadoProcesoOperativo'].setValue(this.procesosGet[0].fec_registro_procesooperativo);
        this.MarcadoProcesosForm.controls['FechaMarcadoProcesoOperativo'].disable();
      }
      if (this.procesosGet[0].clv_procesodefacturacion === 1) {
        this.MarcadoProcesosForm.controls['ProcesoFacturacion'].setValue(true);
        this.MarcadoProcesosForm.controls['StrUsuarioProcesoFacturacion'].setValue(this.procesosGet[0].nom_usuarioregistro_procesodefacturacion);
        this.MarcadoProcesosForm.controls['StrUsuarioProcesoFacturacion'].disable();
        this.MarcadoProcesosForm.controls['FechaMarcadoProcesoFacturacion'].setValue(this.procesosGet[0].fec_registro_procesodefacturacion);
        this.MarcadoProcesosForm.controls['FechaMarcadoProcesoFacturacion'].disable();
      }
      if (this.procesosGet[0].clv_registroderutas === 1) {
        this.MarcadoProcesosForm.controls['RegistroRuta'].setValue(true);
        this.MarcadoProcesosForm.controls['StrUsuarioRegistroRuta'].setValue(this.procesosGet[0].nom_usuarioregistro_registroderutas);
        this.MarcadoProcesosForm.controls['StrUsuarioRegistroRuta'].disable();
        this.MarcadoProcesosForm.controls['FechaMarcadoRegistroRuta'].setValue(this.procesosGet[0].fec_registro_registroderutas);
        this.MarcadoProcesosForm.controls['FechaMarcadoRegistroRuta'].disable();
      }
      if (this.procesosGet[0].clv_registrodetabuladores === 1) {
        this.MarcadoProcesosForm.controls['RegistroTabulador'].setValue(true);
        this.MarcadoProcesosForm.controls['StrUsuarioRegistroTabulador'].setValue(this.procesosGet[0].nom_usuarioregistro_registrodetabuladores);
        this.MarcadoProcesosForm.controls['StrUsuarioRegistroTabulador'].disable();
        this.MarcadoProcesosForm.controls['FechaMarcadoRegistroTabulador'].setValue(this.procesosGet[0].fec_registro_registrodetabuladores);
        this.MarcadoProcesosForm.controls['FechaMarcadoRegistroTabulador'].disable();
      }
      if (this.procesosGet[0].clv_registropao === 1) {
        this.MarcadoProcesosForm.controls['RegistroPAO'].setValue(true);
        this.MarcadoProcesosForm.controls['StrUsuarioRegistroPAO'].setValue(this.procesosGet[0].nom_usuarioregistro_registropao);
        this.MarcadoProcesosForm.controls['StrUsuarioRegistroPAO'].disable();
        this.MarcadoProcesosForm.controls['FechaMarcadoRegistroPAO'].setValue(this.procesosGet[0].fec_registro_registropao);
        this.MarcadoProcesosForm.controls['FechaMarcadoRegistroPAO'].disable();
      }
      }, 300);
    },(error) => {
      this.message.add({ severity: 'error', summary: 'Error en <Marcar Procesos>', detail: 'No se Puede Ingresar Sin haber Aceptado Una Propuesta' });
    });

  }

  bloquear() {
    this.blockedPanel = this.blockedPanel === false ? true : false;
    let leyenda: string = this.blockedPanel === false ? 'DESBLOQUEAR A ESTE CLIENTE' : 'BLOQUEAR A ESTE CLIENTE Y MARCARLO COMO CLIENTE A FUTURO';
    let valor: number = this.blockedPanel === false ? 1 : 0;
    this.confirmationService.confirm({
      message: 'Esta Seguro que Desea ' + leyenda + '?',
      header: 'Confirmacion de Cambio de Estatus de Cliente',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.bolComercial = this.blockedPanel === false ? true : false;
        let objeto_pasar = {
          clv_desactivado: valor,
          id_cte_prospecto: this.id_global
        }
        this.ClienteProspectoService.CambioEstatusCliente(objeto_pasar).subscribe((resp) => {
          setTimeout(() => {
            this.getListadoClientesProspecto();
            this.StrBloqueado = valor === 1 ? 'Marcar como Cliente a Futuro' : 'Desmarcar a Este Cliente de Cliente a Futuro'
            this.controlform.controls['chckbox'].setValue(false);
            this.message.add({ severity: 'success', summary: 'Se ha Modificado el Estado del Cliente', detail: 'Accion Realizada Exitosamente' });
          }, 800);
        });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.blockedPanel = !this.blockedPanel;
            this.controlform.controls['chckbox'].setValue(false);
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.blockedPanel = !this.blockedPanel;
            this.controlform.controls['chckbox'].setValue(false);
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    })
  }
  CerrarPropuesta() {
    this.dspPropuestaComercial = false;
    this.documents = this.documentsDevuelto;
    this.blockedPanel = false;
    this.hasMouseEntered = false;
  }

  ObtenerNombres(id: number) {
    this.ClienteProspectoService.GetNombresPDF(id).subscribe((resp) => {
      this.NombresPDF = resp;
      setTimeout(() => {
      }, 600);
    })
  }

  ObtenerPuestos() {
    this.ClienteProspectoService.GetPuestos().subscribe((resp) => {
      this.Puestos = resp;
      setTimeout(() => {
      }, 600);
    })
  }

  ObtenerCatSoporteCobranza() {
    this.ClienteProspectoService.GetSoporteCobranza().subscribe((resp) => {
      this.catSoporteCobranza = resp
      setTimeout(() => {
      }, 400);
    })
  }

  ObtenerIndicadores() {
    this.ClienteProspectoService.getIndicadores().subscribe((resp) => {
      this.indicadoresDes = resp;
      setTimeout(() => {
      }, 300);
    })
  }

  ObtenerConfiguracion() {
    this.ClienteProspectoService.getConfiguracionUnidades().subscribe((resp) => {
      this.confUnidades = resp;
      setTimeout(() => {
      }, 200);
    })
  }

  public CambiarEstatus(event: any, data: ClientesProspecto) {
    let nvoestatus = event.value;
    let nvoobj = this.ModeloEstatus.find(objeto => objeto.id_orden === nvoestatus);
    let strNuevoEstatus: string;
    if (nvoestatus - 1 !== this.valorEstatusPaso || nvoestatus < this.valorEstatusPaso) {
      this.confirmationService.confirm({
        message: 'Ocurrio un Problema, el Estatus al que se Desea Cambiar es Incorrecto, Solo se Puede Cambiar su Estatus a: <b>' + this.ModeloEstatus[this.valorEstatusPaso].estatus + '</b>',
        header: 'Confirme si Desea Cambiar su Estatus a ' + this.ModeloEstatus[this.valorEstatusPaso].estatus + '',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (nvoobj) {
            if (nvoobj.estatus) {
              strNuevoEstatus = nvoobj.estatus;
              let objetopasar = {
                id_cte_prospecto: data.id_cte_prospecto,
                id_estatus: this.ModeloEstatus[this.valorEstatusPaso].id_orden
              }
              this.ClienteProspectoService.saveMovimientoEstatus(objetopasar).subscribe((resp) => {
                setTimeout(() => {
                  this.getListadoClientesProspecto();
                  this.valorEstatus = 0;
                  this.message.add({ severity: 'success', summary: 'Estatus Modificado exitosamente', detail: 'El estatus se modifico' });
                }, 800);
              });
            }
          }
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
              this.valorEstatusPaso = 0;
              this.valorEstatus = 0;
              this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
              break;
            case ConfirmEventType.CANCEL:
              this.valorEstatusPaso = 0;
              this.valorEstatus = 0;
              this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
              break;
          }
        }
      })
    } else {
      if (nvoobj) {
        if (nvoobj.estatus && nvoobj.id_orden) {
          strNuevoEstatus = nvoobj.estatus;
          //data.des_estatus=nvoobj.estatus;
          this.confirmationService.confirm({
            message: 'Esta seguro que desea Cambiar el Estatus a: <b>' + strNuevoEstatus + '</b>?',
            header: 'Confirmacion de Cambio Estatus',
            icon: 'pi pi-info-circle',
            accept: () => {
              let objetopasar = {
                id_cte_prospecto: data.id_cte_prospecto,
                id_estatus: nvoobj?.id_orden
              }
              this.ClienteProspectoService.saveMovimientoEstatus(objetopasar).subscribe((resp) => {
                setTimeout(() => {
                  this.getListadoClientesProspecto();
                  this.valorEstatus = 0;
                  this.message.add({ severity: 'success', summary: 'Estatus Modificado exitosamente', detail: 'El estatus se modifico' });
                }, 800);
              });
            },
            reject: (type: any) => {
              switch (type) {
                case ConfirmEventType.REJECT:
                  this.valorEstatusPaso = 0;
                  this.valorEstatus = 0;
                  this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
                  break;
                case ConfirmEventType.CANCEL:
                  this.valorEstatusPaso = 0;
                  this.valorEstatus = 0;
                  this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
                  break;
              }
            }
          });
        }
      }
    }

  }

  ValorEstatus(data: string) {
    let obj = this.ModeloEstatus.find(obj => obj.estatus === data);
    let num: number = 0;
    if (obj) {
      if (obj.id_orden) {
        num = obj.id_orden;
      }
    }
    this.valorEstatusPaso = num;
    this.valorEstatus = num;
  }

  ObtenerInformacionBrief() {
    //this.loading = true;

    this.ClienteProspectoService.GetInformacionBrief(this.idclienteprospecto).subscribe((resp) => {
      if (resp) {
        let obj: any;
        obj = resp;
        if (obj.clientesBrief.length > 0) {
          this.numero_operacion = 2
        } else if (obj.clientesBrief.length === 0) {
          this.numero_operacion = 1;
        }
        // Verifica si obj.clientesBrief existe y si tiene el campo corporativo
        if (Object.keys(obj.clientesBrief).length !== 0) {
          if (obj.clientesBrief && obj.clientesBrief[0].corporativo !== undefined) {

            this.informacionBrief.clientesBrief = obj.clientesBrief;
            this.informacionBrief.ciudad = obj.estadoMunicipio;
            this.informacionBrief.config_unidad = obj.configuracionUnidad;
            this.informacionBrief.indicador_des = obj.indicadorDesempenio;
            this.informacionBrief.soporteCobranza = obj.soporteCobranza;
            this.informacionBrief.tipos_Servicio = obj.tiposServicios;

            // Asigna el valor de corporativo al formulario solo si existe
            this.BriefForm.controls['corporativo'].setValue(obj.clientesBrief[0].corporativo);
            this.MunicipiosSeleccionados = obj.estadoMunicipio.map((indicador: { municipio: string; }) => indicador.municipio);

            // Mapea los datos devueltos a objdevueltoCiudad
            this.objdevueltoCiudad = obj.estadoMunicipio.map((indicador: any) => ({
              municipio: indicador.municipio,
              lv: indicador.lv,
              v_3_5: indicador.v_3_5,
              rabon: indicador.rabon,
              th: indicador.th,
              _53FT: indicador._53FT,
              usuario: indicador.usuario,
              celular: indicador.celular
            }));

            // Si objdevueltoCiudad tiene datos, llena el formulario correspondiente
            if (this.objdevueltoCiudad && this.objdevueltoCiudad.length) {
              this.populateMunicipiosForms(this.objdevueltoCiudad);
            }

            this.BriefForm.controls['caracteristicasProyecto'].setValue(obj.clientesBrief[0].caracteristicasProyecto);
            this.BriefForm.controls['importeVenta'].setValue(obj.clientesBrief[0].imp_venta_estimada);
            this.BriefForm.controls['fecha_inicio'].setValue(new Date(obj.clientesBrief[0].fec_inicio));
            this.BriefForm.controls['fecha_termino'].setValue(new Date(obj.clientesBrief[0].fec_fin));
            this.BriefForm.controls['num_DiasCredito'].setValue(obj.clientesBrief[0].num_DiasCredito);
            this.BriefForm.controls['periodoFacturacion'].setValue(obj.clientesBrief[0].periodoFacturacion);
            let campo: string = '';
            campo = obj.clientesBrief[0].clasificacion_producto;
            let objet = this.subclasificacion.find(obj => obj.nom_subclasificacion === campo);
            if (objet) {
              if (objet.id_sub_clasificacion_de_cte) {
                this.BriefForm.controls['clasificacion_producto'].setValue(objet.id_sub_clasificacion_de_cte);
              }
            }
            this.BriefForm.controls['tipoProducto'].setValue(obj.clientesBrief[0].id_tipoproducto);
            this.BriefForm.controls['pesoMercancia'].setValue(obj.clientesBrief[0].num_pesomercancia);
            this.BriefForm.controls['num_choferes'].setValue(obj.clientesBrief[0].num_choferes);
            this.BriefForm.controls['num_auxiliares'].setValue(obj.clientesBrief[0].num_auxiliares);
            this.BriefForm.controls['horas_servicio'].setValue(obj.clientesBrief[0].horas_servicio);
        
            this.BriefForm.controls['horario_carga'].setValue(obj.clientesBrief[0].horario_carga);
 
            //descarga
            
              this.BriefForm.controls['horario_descarga'].setValue(obj.clientesBrief[0].horario_descarga);
            this.BriefForm.controls['tiempoCarga'].setValue(obj.clientesBrief[0].num_tiempocarga);
            this.BriefForm.controls['tiempoDescarga'].setValue(obj.clientesBrief[0].num_tiempodescarga);
            this.BriefForm.controls['tiempoEspera_Estadia'].setValue(obj.clientesBrief[0].num_tiempoespera_estadia);
            
            //let camp: number = 0;
            //camp = Number(obj.clientesBrief[0].des_recibe_descarga);
            this.BriefForm.controls['recibe_descarga'].setValue(obj.clientesBrief[0].des_recibe_descarga);
            this.BriefForm.controls['realiza_descarga'].setValue(obj.clientesBrief[0].id_realiza_descarga);
            this.BriefForm.controls['proceso_descarga'].setValue(obj.clientesBrief[0].des_proceso_descarga);
            this.BriefForm.controls['tipo_maniobradescarga'].setValue(obj.clientesBrief[0].tipo_maniobradescarga);
            this.BriefForm.controls['genera_carga'].setValue(obj.clientesBrief[0].id_genera_carga);
            this.BriefForm.controls['lugar_carga'].setValue(obj.clientesBrief[0].id_lugar_carga);
            this.BriefForm.controls['tipo_maniobracarga'].setValue(obj.clientesBrief[0].tipo_maniobracarga);
            this.BriefForm.controls['tipo_caja'].setValue(obj.clientesBrief[0].id_tipo_caja);
            this.BriefForm.controls['regreso_tarimas'].setValue(obj.clientesBrief[0].id_regreso_tarimas);
            this.BriefForm.controls['frecuencia_visita'].setValue(obj.clientesBrief[0].id_frecuencia_visita);
            this.BriefForm.controls['tipo_ruta'].setValue(obj.clientesBrief[0].id_tipo_ruta);
            this.BriefForm.controls['tipo_confirmacion'].setValue(obj.clientesBrief[0].id_tipo_confirmacion);
            this.BriefForm.controls['des_comentariosproyecto'].setValue(obj.clientesBrief[0].des_comentariosproyecto);
            this.BriefForm.controls['des_mercancia'].setValue(obj.clientesBrief[0].des_mercancia);
            this.mercanciaSelect = obj.clientesBrief[0].des_mercancia;
            this.MercanciaChange();
            this.BriefForm.controls['des_tamaño'].setValue(obj.clientesBrief[0].des_tamaniomercancia);
            this.BriefForm.controls['id_maniobrasyserviciosadicionales'].setValue(obj.clientesBrief[0].id_maniobrasyserviciosadicionales);
            this.BriefForm.controls['id_lugar_descarga'].setValue(obj.clientesBrief[0].id_lugar_descarga);


            this.usuariosSeleccionados = [...obj.soporteCobranza.map((indicador: { id_soportecobranza: number; }) => indicador.id_soportecobranza)];
            this.tiposProyectoSeleccionados = [...obj.tiposServicios.map((indicador: { id_proyecto: number; }) => indicador.id_proyecto)];
            this.configuracionesSeleccionadas = [...obj.configuracionUnidad.map((indicador: { clave_configuracionUnidad: number; }) => indicador.clave_configuracionUnidad)];
            this.indicadoresSeleccionados = [...obj.indicadorDesempenio.map((indicador: { id_indicadordesempenio: number; }) => indicador.id_indicadordesempenio)];
            this.contadorIndicadores = this.indicadoresSeleccionados.length;
            this.contadorServicios = this.usuariosSeleccionados.length;
            this.contadorTipoProyecto = this.tiposProyectoSeleccionados.length;
            this.contadorConfiguraciones = this.configuracionesSeleccionadas.length;

            this.BriefForm.controls['penalizaciones'].setValue(obj.clientesBrief[0].penalizaciones);

            if (obj.clientesBrief[0].urlPortalComercial !== '') {
              this.BriefForm.controls['urlPortalComercial'].setValue(obj.clientesBrief[0].urlPortalComercial);
              this.BriefForm.controls['chek'].setValue(true);
              this.BriefForm.controls['urlPortalComercial'].enable();
            }

            if (obj.clientesBrief[0].urlPortalOperativo !== '') {
              this.BriefForm.controls['urlPortalOperativo'].setValue(obj.clientesBrief[0].urlPortalOperativo);
              this.BriefForm.controls['chet'].setValue(true);
              this.BriefForm.controls['urlPortalOperativo'].enable();
            }

            if (obj.clientesBrief[0].nombreAplicacion !== '') {
              this.BriefForm.controls['nombreAplicacion'].setValue(obj.clientesBrief[0].nombreAplicacion);
              this.BriefForm.controls['AplicacionFlag'].setValue(true);
              this.BriefForm.controls['nombreAplicacion'].enable();
            }

            this.BriefForm.controls['area_Geografica'].setValue(obj.clientesBrief[0].area_Geografica);
            //et forma:FormData=new FormData(obj.archivo);
            this.NombresPDFManuales = obj.archivo;
            //this.objFormData.append('files', forma);
            this.contador = this.objFormData.append.length;
          } else {
            console.error('No se pudo obtener la propiedad corporativo de clientesBrief');
          }
        }

        setTimeout(() => {
          this.loading = false;
        }, 500);
      } else {
        console.error('No existe informacion en el servidor');
      }
    });
  }


  agregarItem() {
    let obj = this.estados.find(objeto => objeto.id === this.BriefForm.get('estado')?.value);
    let campo: string = '';
    if (obj) {
      campo = obj.estado;
    }
    let muniobj = this.municipios.find(objeto => objeto.id_Ciudad === this.BriefForm.get('municipio')?.value);
    let campo2: string = '';
    if (muniobj) {
      campo2 = muniobj.municipios;
    }
    this.estadoMunicipio.push({ estado: campo, municipio: campo2 });
    this.BriefForm.get('estado')?.reset();
    this.BriefForm.get('municipio')?.reset();
  }

  ObtenerClasificacionPuestos() {
    this.ClienteProspectoService.GetClasificacionPuestos().subscribe((resp) => {
      
      this.ClasificacionPuestos = resp;
      setTimeout(() => {
      }, 600);
    })
  }

  onSelect(event: Iuploadevent) {
    const selectedFile: File = event.files[0];
    this.uploadedSoporte.push(selectedFile);
  }
  viewPdf(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      const fileURL = reader.result as string;
      const pdfWindow = window.open();
      pdfWindow?.document.write(`
        <iframe src="${fileURL}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>
      `);
    };
    reader.readAsDataURL(file);
  }

  onUpload(event: Iuploadevent) {
    const formData = new FormData();
    for (let file of event.files) {
      formData.append('files', file);
    }

    let datos = {
      id_cte_prospecto: this.idclienteprospecto,
      nom_empresa: this.NomEmpresaGlobal
    }
    
    this.ClienteProspectoService.SaveArchivos(formData, datos).subscribe((resp) => {
      setTimeout(() => {
        this.ObtenerNombres(this.idclienteprospecto);
        this.ChangeEstatus(this.idclienteprospecto,3);
        this.message.add({ severity: 'success', summary: 'Archivo Correcto', detail: 'Archivo Subido Exitosamente' });
      }, 600);
    })
  }

  ChangeEstatus(id:number,numero:number){
    let objetopasar = {
      id_cte_prospecto: id,
      id_estatus: numero
    }
    this.ClienteProspectoService.saveMovimientoEstatus(objetopasar).subscribe((resp) => {
      setTimeout(() => {
        this.getListadoClientesProspecto();
        this.valorEstatus = 0;
      }, 250);
    });
  }

  Subir(event: Iuploadevent) {
    const formData = new FormData();
    for (let file of event.files) {
      formData.append('files', file);
    }


    let datos = {
      id_cte_prospecto: this.idclienteprospecto,
      nom_empresa: this.NomEmpresaGlobal
    }
    this.ClienteProspectoService.SaveArchivos(formData, datos).subscribe((resp) => {
      setTimeout(() => {
        this.ObtenerNombres(this.idclienteprospecto);
        this.message.add({ severity: 'success', summary: 'Archivo Correcto', detail: 'Archivo Subido Exitosamente' });
      }, 800);
    })
  }

  SubirArchivoBrief(event: Iuploadevent) {

    for (let file of event.files) {
      this.uploadedSoporte
    }

    for (let file of event.files) {
      this.objFormData.append('files', file);
      this.contador = this.contador + 1;
    }
    const files: File[] = event.files;
    this.BriefForm.controls['manualDocumentos'].setValue(this.objFormData);
  }

  VerArchivo(data: any) {
    let datos = {
      nombreArchivo: data.nom_archivo,
      nom_empresa: this.NomEmpresaGlobal
    }
    this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe(
      (archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();

        link.remove();
      },
      error => {
        console.error('Error al obtener archivo:', error);
      }
    );
  }

  VerArchivoBrief(data: any) {
    let datos = {
      nombreArchivo: data.nom_archivo,
      nom_empresa: this.NomEmpresaGlobal
    }
    this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe(
      (archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();

        link.remove();
      },
      error => {
        console.error('Error al obtener archivo:', error);
      }
    );
  }

  DescargarArchivoBrief(data: any) {
    let datos = {
      nombreArchivo: data.nom_archivo,
      nom_empresa: this.NomEmpresaGlobal
    }
    this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe(
      (archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', data.nom_archivo); // El nombre del archivo se mantiene igual

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();

        link.remove();
      },
      error => {
        console.error('Error al obtener archivo:', error);
      }
    );
  }


  DescargarArchivo(data: any) {
    let datos = {
      nombreArchivo: data.nom_archivo,
      nom_empresa: this.NomEmpresaGlobal
    }
    this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe(
      (archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', data.nom_archivo); // El nombre del archivo se mantiene igual

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      error => {
        console.error('Error al obtener archivo:', error);
      }
    );
  }

  convertTo24HourFormat(time: string): Date {
    const [hourPart, minutePart] = time.split(':');
    const [minutes, period] = minutePart.split(' ');
    let hours = parseInt(hourPart);

    if (period === 'PM' && hours < 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    const date = new Date();
    date.setHours(hours, parseInt(minutes), 0, 0);
    return date;
  }

  calculateTimeDifference(start: Date, end: Date): string {
    const diff = end.getTime() - start.getTime();
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${this.padNumber(hours)}:${this.padNumber(minutes)}`;
  }



  padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  createCityGroup(): FormGroup {
    return this.fb.group({
      ciudad: ['', Validators.required],
      lv: [0, Validators.required],
      v_3_5: [0, Validators.required],
      rabon: [0, Validators.required],
      th: [0, Validators.required],
      _53FT: [0, Validators.required],
      usuario: ['', Validators.required],
      celular: ['', Validators.required]
    });
  }


  addCity(index: number): void {
    // Verificar que ciudadesselect[index] no sea undefined o null
    // Añadir la ciudad seleccionada al arreglo objCiudad
    this.objCiudad.push({
      municipio: this.ciudadesform.get('ciudad')?.value[index],
      lv: this.ciudadesform.get('lv')?.value,
      v_3_5: this.ciudadesform.get('v_3_5')?.value,
      rabon: this.ciudadesform.get('rabon')?.value,
      th: this.ciudadesform.get('th')?.value,
      _53FT: this.ciudadesform.get('_53FT')?.value,
      usuario: this.ciudadesform.get('usuario')?.value,
      celular: this.ciudadesform.get('celular')?.value
    });
    this.MunicipiosSeleccionados.splice(index, 1);

  }

  borrarobjetos(){
    this.lstNombreConfiguracion=[];
    this.lstTipoIndicador=[];
    this.lstNombreTipoServicio=[];
    this.lstNombreSoporte=[];
    this.objCiudad=[];
  }


  guardarInformacionBrief() {

    // Aquí puedes enviar `data` a tu backend o manejarlo como desees.

    /*const CiudadesSelecciondos = this.BriefForm.get('ciudades')?.value;

    if (!CiudadesSelecciondos || CiudadesSelecciondos.length === 0) {
      return this.message.add({ severity: 'danger', summary: 'Error', detail: 'Debe seleccionar al menos un usuario' });
    }
    if (this.MunicipiosSeleccionados.length > 0) {
      this.MunicipiosSeleccionados.map((val) => {
        let indicador: catMunicipios = {
          municipio: val
        };
        indicador.municipio = val;
        this.lstMunicipios.push(indicador);
      });
    }*/

    const TipoIndicadorSeleccionado = this.BriefForm.get('indicadoresDesempeno')?.value;

    if (!TipoIndicadorSeleccionado || TipoIndicadorSeleccionado.length === 0) {
      return this.message.add({ severity: 'danger', summary: 'Error', detail: 'Debe seleccionar al menos un usuario' });
    }

    if (this.indicadoresSeleccionados.length > 0 || this.contadorIndicadores>0) {
      this.indicadoresSeleccionados.map((val)=>{
        let indicador:ITipoIndicador={
          id_indicadordesempenio:val
        };
        this.lstTipoIndicador.push(indicador);
      })
    }



    const TipoConfiguracion = this.BriefForm.get('configuracion_Unidades')?.value;

    if (!TipoConfiguracion || TipoConfiguracion.length === 0) {
      return this.message.add({ severity: 'danger', summary: 'Error', detail: 'Debe seleccionar al menos un usuario' });
    }


    if (this.configuracionesSeleccionadas.length > 0 || this.contadorConfiguraciones >0) {
      this.configuracionesSeleccionadas.map((val)=>{
        let indicador:INombreConfiguracion={
          clave_configuracionUnidad:val
        };
        this.lstNombreConfiguracion.push(indicador);
      })
    }

    const NombreSoportes = this.BriefForm.get('soporteCobranza')?.value;

    if (!NombreSoportes || NombreSoportes.length === 0) {
      return this.message.add({ severity: 'danger', summary: 'Error', detail: 'Debe seleccionar al menos un usuario' });
    }

    if (this.usuariosSeleccionados.length > 0 || this.contadorServicios>0) {
      this.usuariosSeleccionados.map((val)=>{
        let indicador:INombreSoporte={
          id_soportecobranza:val
        };
        this.lstNombreSoporte.push(indicador);
      })
    }

    const NombresTipoServicio = this.BriefForm.get('tipos_Servicio')?.value;

    if (!NombresTipoServicio || NombresTipoServicio.length === 0) {
      return this.message.add({ severity: 'danger', summary: 'Error', detail: 'Debe seleccionar al menos un usuario' });
    }

    if (this.tiposProyectoSeleccionados.length > 0 || this.contadorTipoProyecto >0) {
      this.tiposProyectoSeleccionados.map((val)=>{
        let indicador:INombreTipoServicio={
          id_proyecto:val
        };
        this.lstNombreTipoServicio.push(indicador);
      })
    }
    this.confirmationService.confirm({
      message: 'Esta seguro que desea Guardar la Informacion ?',
      header: 'Confirmacion de Guardado',
      icon: 'pi pi-info-circle',
      accept: () => {
        let data_enviar = {
          nom_Cliente: this.BriefForm.get('nom_Cliente')?.value,
          id_cte_prospecto: this.idclienteprospecto,
          tipo_Proyecto: this.BriefForm.get('tipoProyecto')?.value,
          fecha: this.BriefForm.get('fecha')?.value,
          correoContacto: this.BriefForm.get('correoContacto')?.value,
          corporativo: this.BriefForm.get('corporativo')?.value,
          estadoMunicipio: this.objdevueltoCiudad.length>this.objCiudad.length?this.objdevueltoCiudad:this.objCiudad,
          imp_venta_estimada: this.BriefForm.get('importeVenta')?.value,
          fec_inicio: this.BriefForm.get('fecha_inicio')?.value,
          fec_fin: this.BriefForm.get('fecha_termino')?.value,
          periodoFacturacion: this.BriefForm.get('periodoFacturacion')?.value,
          num_DiasCredito: this.BriefForm.get('num_DiasCredito')?.value,
          caracteristicasProyecto: this.BriefForm.get('caracteristicasProyecto')?.value,
          soporteCobranza: this.isEmpty(this.lstNombreSoporte)===true ? this.usuariosSeleccionados : this.lstNombreSoporte,
          tiposServicios: this.isEmpty(this.lstNombreTipoServicio)===true? this.tiposProyectoSeleccionados:this.lstNombreTipoServicio,
          configuracionUnidad: this.isEmpty(this.lstNombreConfiguracion)===true?this.configuracionesSeleccionadas:this.lstNombreConfiguracion,
          indicadorDesempenio: this.isEmpty(this.lstTipoIndicador)===true?this.indicadoresSeleccionados: this.lstTipoIndicador,
          penalizaciones: this.BriefForm.get('penalizaciones')?.value,
          urlPortalComercial: this.BriefForm.get('urlPortalComercial')?.value===null?'':this.BriefForm.get('urlPortalComercial')?.value,
          urlPortalOperativo: this.BriefForm.get('urlPortalOperativo')?.value===null?'':this.BriefForm.get('urlPortalOperativo')?.value,
          nombreAplicacion: this.BriefForm.get('nombreAplicacion')?.value===null?'':this.BriefForm.get('nombreAplicacion')?.value,
          area_Geografica: this.BriefForm.get('area_Geografica')?.value,
          id_tipoproducto: this.BriefForm.get('tipoProducto')?.value,
          num_pesomercancia: this.BriefForm.get('pesoMercancia')?.value,
          horario_carga: this.BriefForm.get('horario_carga')?.value,
          horario_descarga: this.BriefForm.get('horario_descarga')?.value,
          des_recibe_descarga: this.BriefForm.get('recibe_descarga')?.value,
          num_tiempocarga: this.BriefForm.get('tiempoCarga')?.value,
          num_tiempodescarga: this.BriefForm.get('tiempoDescarga')?.value,
          num_tiempoespera_estadia: this.BriefForm.get('tiempoEspera_Estadia')?.value,
          des_proceso_descarga: this.BriefForm.get('proceso_descarga')?.value,
          id_genera_carga: this.BriefForm.get('genera_carga')?.value,
          id_lugar_carga: this.BriefForm.get('lugar_carga')?.value,
          id_tipo_caja: this.BriefForm.get('tipo_caja')?.value,
          id_regreso_tarimas: this.BriefForm.get('regreso_tarimas')?.value,
          id_frecuencia_visita: this.BriefForm.get('frecuencia_visita')?.value,
          id_realiza_descarga: this.BriefForm.get('realiza_descarga')?.value,
          tipo_maniobradescarga: this.BriefForm.get('tipo_maniobradescarga')?.value,
          tipo_maniobracarga: this.BriefForm.get('tipo_maniobracarga')?.value,
          id_tipo_ruta: this.BriefForm.get('tipo_ruta')?.value,
          id_tipo_confirmacion: this.BriefForm.get('tipo_confirmacion')?.value,
          num_choferes: this.BriefForm.get('num_choferes')?.value,
          num_auxiliares: this.BriefForm.get('num_auxiliares')?.value,
          horas_servicio: this.BriefForm.get('horas_servicio')?.value,
          clasificacion_producto: this.BriefForm.get('clasificacion_producto')?.value,
          des_mercancia: this.BriefForm.get('des_mercancia')?.value,
          des_tamaniomercancia: this.BriefForm.get('des_tamaño')?.value,
          des_comentariosproyecto: this.BriefForm.get('des_comentariosproyecto')?.value,
          des_manualdeoperaciones: 'X',
          id_lugar_descarga: this.BriefForm.get('id_lugar_descarga')?.value,
          id_maniobrasyserviciosadicionales: this.BriefForm.get('id_maniobrasyserviciosadicionales')?.value
        };
        try {
          this.ClienteProspectoService.saveInformacionBrief(this.idclienteprospecto, this.NomEmpresaGlobal, data_enviar).pipe(
            switchMap(respSaveInfo => {
              // Primera llamada HTTP para guardar la información del Brief
              var resultado = respSaveInfo;

              // Se ejecuta la segunda llamada HTTP para editar el cliente prospecto
              this.objetoCtesProspectoPasar[0].opcion = 2;
              this.objetoCtesProspectoPasar[0].flag_tabulador = 1;
              this.borrarobjetos();
              return this.ClienteProspectoService.editClienteProspecto(this.objetoCtesProspectoPasar[0]).pipe(
                switchMap(respEdit => {
                  // Se ejecuta la tercera llamada HTTP para guardar los archivos
                  return new Observable(observer => {
                    let datos = {
                      id_cte_prospecto: this.idclienteprospecto,
                      nom_empresa: this.NomEmpresaGlobal
                    };
                    if (this.numero_operacion === 1) {
                      this.ClienteProspectoService.SaveArchivoBrief(this.objFormData, datos).subscribe(respSaveFiles => {
                        observer.next();
                        observer.complete();
                      });
                    } else if (this.numero_operacion === 2 && this.contador === this.objFormData.append.length) {
                      observer.next();
                      observer.complete();
                    }
                    else if (this.numero_operacion === 2 && this.objFormData !== null) {
                      this.ClienteProspectoService.SaveArchivoBrief(this.objFormData, datos).subscribe(respSaveFiles => {
                        observer.next();
                        observer.complete();
                      });
                    }
                  });
                })
              );
            })
          ).subscribe(() => {
            this.ObtenerInformacionBrief();
            this.ChangeEstatus(this.idclienteprospecto,4);
            this.uploadedSoporte = [];
            this.message.add({ severity: 'success', summary: 'Informacion de Brief', detail: 'La Informacion se Almaceno de Forma Correcta' });
          });
        } catch (error) {
        }
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    });

    /*else
     if (this.numero_operacion == 2) {
       this.ClienteProspectoService.editClienteProspecto(data_enviar).subscribe((resp) => {
         var resultado = resp;
         setTimeout(() => {
           this.getListadoClientesProspecto();
           this.message.add({ severity: 'success', summary: 'Edicion Exitosa', detail: 'la informacion se edito correctamente' });
           this.ClienteProspectoForm.reset();
           this.cancelarVentanaModal();
         }, 800);
       })
     }*/
  }
  isEmpty(obj: any): boolean {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj) && Object.keys(obj).length === 0;
  }

  CerrarBriefComercial() {
    this.BriefForm.reset();
    this.indicadoresSeleccionados = [];
    this.configuracionesSeleccionadas = [];
    this.tiposProyectoSeleccionados = [];
    this.usuariosSeleccionados = [];
    this.dspBriefComercial = false;
  }
  IrNavegador(data: ClientesProspecto) {
    const navigationExtras: NavigationExtras = {
      state: {
        datos: data
      }
    };
    this.router.navigateByUrl("/tabulador-costos", navigationExtras);
  }

  verPDFbrief() {
    this.router.navigateByUrl("/pdf-brief");
  }

  delete(data: any) {
    //const selectedFile: File = file.files[0];
    this.confirmationService.confirm({
      message: 'Esta seguro que desea Eliminar el Archivo <b>' + data.nom_archivo + '?',
      header: 'Confirmacion de Eliminacion',
      icon: 'pi pi-info-circle',
      accept: () => {
        let datos = {
          nombreArchivo: data.nom_archivo,
          nom_empresa: this.NomEmpresaGlobal,
          idx: data.idx,
          num_operacion: 1
        }
        this.ClienteProspectoService.DeleteArchivo(datos).subscribe((resp) => {
          setTimeout(() => {
            this.ObtenerInformacionBrief();
            this.ObtenerNombres(this.idclienteprospecto);
            this.message.add({ key: 'msj', severity: 'success', summary: 'Hecho', detail: 'Se ha Eliminado el Archivo Exitosamente' });
          }, 500);
        })
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    });
  }

  onActiveIndexChange(event: number) {
    this.activeIndex = event;
  }

  MostrarSubirArchivos() {
    this.check = !this.check;
    this.ObtenerNombres(this.idclienteprospecto);
  }
  MostrarPortal() {
    this.chec = !this.chec;
    if (this.BriefForm.controls['urlPortalComercial'].disabled === true) {
      this.BriefForm.controls['urlPortalComercial'].enable();
    } else {
      this.BriefForm.controls['urlPortalComercial'].disable();
    }
  }
  MostrarPortalOperativo() {
    this.chet = !this.chet;
    if (this.BriefForm.controls['urlPortalOperativo'].disabled === true) {
      this.BriefForm.controls['urlPortalOperativo'].enable();
    } else {
      this.BriefForm.controls['urlPortalOperativo'].disable();
    }
  }
  MostrarAplicacionFlag() {
    this.AplicacionFlag = !this.AplicacionFlag;
    if (this.BriefForm.controls['nombreAplicacion'].disabled === true) {
      this.BriefForm.controls['nombreAplicacion'].enable();
    } else {
      this.BriefForm.controls['nombreAplicacion'].disable();
    }
  }

  obtenerUsuariosComercial() {
    this.calendarioServicio.getUsuariosComercial().subscribe((resp) => {
      this.cat_usuarioscomercial = resp;
      let objeto = this.cat_usuarioscomercial.find(objeto => objeto.cod_usuario === this.usuario.cod);
      if (objeto) {
        this.campo = objeto.nom_empleado;
        this.campo2 = objeto.cod_usuario;
      }
      setTimeout(() => {
      }, 800);
    })
  }

  inicializarFechas() {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.fecha_dia = dateFormat;
    //Formatear fecha de Inicio
    this.BriefForm.controls['fecha'].setValue(this.fecha_dia);
  }

  //Metodos para obtener dropdowns
  getListadoClasificacion() {
    this.ClasificacionService.getClasificacion().subscribe((resp) => {
      this.clasificacion = resp.filter(objeto => objeto.clv_activo === 1);
      setTimeout(() => {
      }, 1000);
    })
  }
  getListadoSubClasificacion() {
    this.SubClasificacionService.getSubClasificacion().subscribe((resp) => {
      this.subclasificacion = resp.filter(objeto => objeto.clv_activo === 1);
      setTimeout(() => {
      }, 1000);
    })
  }
  getListadoMediosContacto() {

    this.MedioDeContactoService.getMedioContacto().subscribe((resp) => {
      this.medioDContacto = resp.filter(objeto => objeto.clv_activo === 1);
      setTimeout(() => {
      }, 1000);
    })
  }
  getListadoClientesProspecto() {
    this.loading = true;
    this.ClienteProspectoService.getClientesProspecto().subscribe((resp) => {
      this.clienteProspecto = resp;
      setTimeout(() => {
        this.loading = false;
      }, 600);
    })
  }

  getListadoEstados() {
    this.ClienteProspectoService.getEstadosMunicipios().subscribe((resp) => {
      this.estados = resp;
      setTimeout(() => {
      }, 1000);
    })
  }

  GenerarOpciones(event: any) {
    let valor = event.value;
  }
  obtenerModeloEstatus() {
    this.ClienteProspectoService.getEstatusLead().subscribe((resp) => {
      this.ModeloEstatus = resp;
      setTimeout(() => {
      }, 150);
    })
  }
  getModeloEstatus() {
    this.ClienteProspectoService.getEstatusLead().subscribe((resp) => {
      this.ModeloEstatus = resp;
      this.items = [
        {
          id: '2',
          label: this.ModeloEstatus[1].estatus,
          icon: 'pi pi-user',
          command: () => {
            this.VerContacto(this.dataGlobal[0].id_cte_prospecto);
          }
        },
        {
          id: '3',
          label: this.ModeloEstatus[2].estatus,
          icon: 'pi pi-pencil',
          command: () => {
            this.dspArchivo = true;
          }
        },
        {
          id: '4',
          label: this.ModeloEstatus[3].estatus,
          icon: 'pi pi-briefcase',
          command: () => {
            this.dspBriefComercial = true;
          }
        },
      ]
      if (this.dataGlobal[0].clv_brief === 1) {
        this.items.push({
          id: '5',
          label: 'DESCARGAR PDF BRIEF',
          icon: 'pi pi-download',
          command: () => {
            this.descargarPDFBrief(this.dataGlobal[0].id_cte_prospecto);
          }
        });
      };
      setTimeout(() => {
      }, 800);
    })
  }
  mostrar(data: ClientesProspecto) {
    this.dataGlobal[0] = data;
    this.getModeloEstatus();
    this.IrAEstatus(data);
  }
  handleItemClick(event: any, data: any) {
    if (event.item.id === '4') {
      // Realizar acciones específicas cuando se selecciona el ítem con id 4

    }
  }

  getMovimientosClientes(id: number) {
    this.ClienteProspectoService.getMovimientosEstatus(id).subscribe((resp) => {
      this.MovimientosCtes = resp;
      this.activeIndex = this.MovimientosCtes[0].id_estatus === undefined ? 0 : this.MovimientosCtes[0].id_estatus - 1;
      this.PasoSeleccionado = this.MovimientosCtes[0].id_estatus === undefined ? 0 : this.MovimientosCtes[0].id_estatus;
      setTimeout(() => {
      }, 300);
    })
  }

  descargarPDFBrief(id: number) {
    this.ClienteProspectoService.DescargarPDFBrief(id).subscribe(
      (archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      error => {
        console.error('Error al obtener archivo:', error);
      }
    )
  }

  cerrarEstatus() {
    this.VerEstatus = false;
    this.PasoSeleccionado = null;
    this.activeIndex = 0;
    this.NombresPDF = [];
    this.NombresPDFManuales = [];
    this.uploadedFiles = [];
    this.ArchivosSubidos = [];
    this.check = true;
    this.BriefForm.reset();
  }

  getListadoMunicipios(event: any) {
    this.selectedValue = event.value;
    this.valor = event.value;
    this.ClienteProspectoService.getMunicipios(this.selectedValue).subscribe((resp) => {
      this.municipios = resp;
      setTimeout(() => {
      }, 1000);
    })
  }

  getListadoProyectos() {
    this.tipoproyectoservice.getProyectos().subscribe((resp) => {
      this.tipoproyectos = resp;
      setTimeout(() => {
      }, 800);
    },
      (error) => {
        this.message.add({ severity: 'error', summary: 'Error en <Cargar Proyectos>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  cargarInformacionUsuario() {
    this.usuario = this.auth.user;
  }

  confirmarDesactivar(data: ClientesProspecto) {
    let estatus_original = data.clv_interesado;
    let objetoEncontrado = this.estatus.find(objeto => objeto.clv_activo === this.valorCombo);
    let campoEspecifico = objetoEncontrado?.des_estatus;
    if (objetoEncontrado) {
      // Accede al campo específico del objeto
      let campoEspecifico = objetoEncontrado.des_estatus;
    } 

    this.confirmationService.confirm({
      message: 'Esta seguro que desea cambiar el estatus a <b>' + campoEspecifico + ' del cliente ' + data.nom_empresa + '</b>?',
      header: 'Confirmacion de Estatus',
      icon: 'pi pi-info-circle',
      accept: () => {
        if (estatus_original === 1) {
          // Si clv_interesado es igual a 1, rechazar la modificación
          this.message.add({ key: 'msj', severity: 'error', summary: 'Error', detail: 'No se pueden realizar modificaciones cuando ya se acepto la propuesta' });
        } else {
          // Realizar la modificación si clv_interesado no es igual a 1
          data.clv_interesado = this.valorCombo;
          this.EdicionDropDown(data);
          this.valorCombo = 0;
        }
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            data.clv_interesado = estatus_original;
            this.valorCombo = estatus_original;
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            data.clv_interesado = estatus_original;
            this.valorCombo = estatus_original;
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    });
  }
  guardarInformacionDesactivar(data: ClientesProspecto) {
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    this.ClienteProspectoService.editClienteProspecto(data).subscribe((resp) => {
      var resultado = resp;
      if (resultado == 1) {
        setTimeout(() => {
          this.message.add({ severity: 'success', summary: 'Registro Exitoso', detail: 'La informacion se almaceno de forma correcta' });
          this.ClienteProspectoForm.reset();
          this.cancelarVentanaModal();
        }, 800);
      }
    },
      (error) => {
        this.message.add({ severity: 'error', summary: 'Error en <Editar Chofer>', detail: 'Contacte al Administrador del Sitio' });
      });
  }

  //fin obtener listados
  dropdown(data: ITabuladorCostos) {
    this.valorNum = data.clv_aceptado;
  }

  SetDropDown(data: ClientesProspecto) {
    this.valorCombo = data.clv_interesado;
  }


  redireccionarNueva(num_operacion: number) {
    this.getListadoClasificacion();
    this.getListadoMediosContacto();
    this.getListadoProyectos();
    this.getListadoSubClasificacion();
    this.getListadoEstados();
    this.numero_operacion = num_operacion;
    this.texto_operacion = '';
    this.ClienteProspectoForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    this.texto_dialog = 'Agregar Nuevo Cliente Prospecto'
    this.texto_operacion = 'Nuevo Cliente Prospecto';
    this.ClienteProspectoForm.controls['clv_interesado'].setValue(2);
    this.ClienteProspectoForm.controls['fec_alta'].setValue(this.fecha_dia);
    this.ClienteProspectoForm.controls['cod_personaseguimiento'].setValue(this.campo2);
    this.ClienteProspectoForm.controls['nom_personaseguimiento'].setValue(this.campo);
    this.ClienteProspectoForm.controls['fec_alta'].enable();
    this.dspNuevoCliente = true;
  }

  onUsuarioChange(event: any) {
    const usuarioSeleccionado = this.cat_usuarioscomercial.find(
        (usuario: any) => usuario.nom_empleado === event.value
    );
    if (usuarioSeleccionado) {
        this.ClienteProspectoForm.get('cod_personaseguimiento')?.setValue(usuarioSeleccionado.cod_usuario);
    }
}

  EdicionDropDown(data: ClientesProspecto) {
    this.data_editar_clienteProspecto = data;
    this.data_editar_clienteProspecto.cod_usuario = this.usuario.cod;
    this.data_editar_clienteProspecto.opcion = 2;
    this.ClienteProspectoService.editClienteProspecto(this.data_editar_clienteProspecto).subscribe((resp) => {
      var resultado = resp;
      setTimeout(() => {
        this.getListadoClientesProspecto();
        this.message.add({ severity: 'success', summary: 'Edicion Exitosa', detail: 'la informacion se edito correctamente' });
        this.ClienteProspectoForm.reset();
        this.cancelarVentanaModal();
        this.valorCombo = 0;
      }, 800);
    })
  }
  verSeleccionados() {
  }

  mostrarModalEditar(data: ClientesProspecto, num_operacion: number) {
    this.data_editar_clienteProspecto = data;
    this.numero_operacion = num_operacion;

    let objetoEncontrado = this.estados.find(estados => estados.estado === data.estado);

    if (objetoEncontrado) {

      if (objetoEncontrado.id !== undefined) {
        let campoEspecifico = objetoEncontrado.id;
        this.valueEstado = objetoEncontrado.estado;
        this.ClienteProspectoForm.controls['estado'].setValue(campoEspecifico);
        this.ClienteProspectoService.getMunicipios(campoEspecifico).subscribe((resp) => {
          this.municipios = resp;
          setTimeout(() => {
            
            let objeto = this.municipios.find(municipio => municipio.municipios === data.municipios);
            if (objeto) {
              if (objeto.id_Ciudad !== undefined) {
                let campo = objeto.id_Ciudad;
                this.selectedvaluestring = objeto.municipios;
                this.ClienteProspectoForm.controls['municipio'].setValue(campo);
              }
            }
          }, 1000);
        })
        //this.ClienteProspectoForm.controls['municipio'].setValue(data.municipios);
      } 
    } 
    this.ClienteProspectoForm.controls['nombreClienteProspecto'].setValue(data.nom_empresa);
    this.ClienteProspectoForm.controls['rfc'].setValue(data.rfc);
    this.ClienteProspectoForm.controls['paginaWeb'].setValue(data.des_paginaweb);
    this.ClienteProspectoForm.controls['municipio'].setValue(data.municipios);
    this.ClienteProspectoForm.controls['cod_personaseguimiento'].setValue(data.cod_personaseguimiento);
    this.ClienteProspectoForm.controls['nom_personaseguimiento'].setValue(data.nom_personaseguimiento);
    this.ClienteProspectoForm.controls['razonSocial'].setValue(data.nom_razonsocial);
    this.ClienteProspectoForm.controls['medioDeContacto'].setValue(data.id_medio_de_contacto);
    this.ClienteProspectoForm.controls['clv_interesado'].setValue(data.clv_interesado);
    this.ClienteProspectoForm.controls['clasificacionCliente'].setValue(data.id_clasificacion_cte);
    this.ClienteProspectoForm.controls['subclasificacionCliente'].setValue(data.id_sub_clasificacion_cte);
    this.ClienteProspectoForm.controls['tipoproyecto'].setValue(data.id_tipo_proyecto);
    this.ClienteProspectoForm.controls['observacionAcercamiento'].setValue(data.desc_observacion);
    this.ClienteProspectoForm.controls['id_cte_prospecto'].setValue(data.id_cte_prospecto);
    this.ClienteProspectoForm.controls['num_folio'].setValue(data.num_folio);
    this.ClienteProspectoForm.controls['clv_tabulador'].setValue(data.clv_tabulador);
    this.dspNuevoCliente = true;
    this.texto_dialog = 'Editar Cliente Prospecto';
  }



  guardarInformacion() {

    let objetoEncontrado = this.estados.find(objeto => objeto.id === this.valor);
    let campoEspecifico: string = '';
    let campo: string = '';
    if (objetoEncontrado) {
      campoEspecifico = objetoEncontrado.estado;
    } 
    //this.ClienteProspectoForm.controls['estado'].setValue(campoEspecifico);
    if (this.numero_operacion == 1) {
      let objetos = this.municipios.find(objetos => objetos.id_Ciudad === this.valorSeleccionado)
      if (objetos) {
        campo = objetos.municipios;
      }
    }

    let data_enviar: ClientesProspecto = {
      cod_usuario: this.usuario.cod,
      clv_interesado: this.numero_operacion == 1 ? 2 : this.ClienteProspectoForm.get('clv_interesado')?.value,
      nom_empresa: this.ClienteProspectoForm.get('nombreClienteProspecto')?.value,
      des_paginaweb: this.ClienteProspectoForm.get('paginaWeb')?.value,
      rfc: this.ClienteProspectoForm.get('rfc')?.value,
      nom_razonsocial: this.ClienteProspectoForm.get('razonSocial')?.value,
      id_medio_de_contacto: this.ClienteProspectoForm.get('medioDeContacto')?.value,
      id_clasificacion_cte: this.ClienteProspectoForm.get('clasificacionCliente')?.value,
      id_sub_clasificacion_cte: this.ClienteProspectoForm.get('subclasificacionCliente')?.value,
      id_tipo_proyecto: this.ClienteProspectoForm.get('tipoproyecto')?.value,
      desc_observacion: this.ClienteProspectoForm.get('observacionAcercamiento')?.value ===null? '':this.ClienteProspectoForm.get('observacionAcercamiento')?.value,
      opcion: this.numero_operacion,
      id_cte_prospecto: this.numero_operacion == 1 ? 0 : this.ClienteProspectoForm.get('id_cte_prospecto')?.value,
      cod_personaseguimiento: this.ClienteProspectoForm.get('cod_personaseguimiento')?.value,
      nom_personaseguimiento: this.ClienteProspectoForm.get('nom_personaseguimiento')?.value,
      clv_aceptado: this.numero_operacion == 1 ? 0 : this.ClienteProspectoForm.get('clv_aceptado')?.value,
      clv_tabulador: this.numero_operacion == 1 ? 0 : this.ClienteProspectoForm.get('clv_tabulador')?.value,
      estado: this.numero_operacion == 2 ? this.valueEstado : campoEspecifico,
      municipios: this.numero_operacion == 2 ? this.selectedvaluestring : campo,
      des_sectorempresa: this.ClienteProspectoForm.get('observacionAcercamiento')?.value===null? '':this.ClienteProspectoForm.get('observacionAcercamiento')?.value,
      num_folio: this.numero_operacion == 2 ? this.ClienteProspectoForm.get('num_folio')?.value : 0,
      clv_operacion: 1,
      flag_tabulador: 0
    };
    console.log("Datos a guardar:", data_enviar);
    if (this.numero_operacion == 1) {
      this.ClienteProspectoService.SaveClienteProspecto(data_enviar).subscribe(
        (resp: any) => {
          // Verifica el estado de la respuesta
          if (resp.clv_estatus === 1) {
            // Si es exitoso
            setTimeout(() => {
              this.getListadoClientesProspecto();
              this.message.add({
                severity: 'success',
                summary: 'cliente prospecto agregado',
                detail: 'La informacion se almaceno de forma correcta',
              });
              this.ClienteProspectoForm.reset();
              this.cancelarVentanaModal();
            }, 800);
          } else if (resp.clv_estatus === 0) {
            // Si hay un RFC repetido
            this.message.add({
              severity: 'error',
              summary: 'Error en agregar cliente prospecto',
              detail: resp.messageDetails || 'El RFC que ingresó ya existe, favor de validarlo',
            });
          }
        },
        (error) => {
          this.message.add({ severity: 'error', summary: 'Error en <agregar cliente prospecto>', detail: 'Contacte al Administrador del Sitio' });
        });
    } else
    if (this.numero_operacion == 2) {
      this.ClienteProspectoService.editClienteProspecto(data_enviar).subscribe(
        (resp: any) => {
          // Verifica el estado de la respuesta
          if (resp.clv_estatus === 1) {
            // Si es exitoso
            setTimeout(() => {
              this.getListadoClientesProspecto();
              this.message.add({
                severity: 'success',
                summary: 'Edición Exitosa',
                detail: 'La información se editó correctamente',
              });
              this.ClienteProspectoForm.reset();
              this.cancelarVentanaModal();
            }, 800);
          } else if (resp.clv_estatus === 0) {
            // Si hay un error, como un RFC repetido
            this.message.add({
              severity: 'error',
              summary: 'Error en editar cliente prospecto',
              detail: resp.messageDetails || 'El RFC que ingresó ya existe, favor de validarlo',
            });
          }
        }
      );
    }
  }

  AgregarContacto(data: ClientesProspecto, numero: number) {
    this.objCteProspecto = data;
    this.numero_operacion = numero;
    this.dspContactoEmpresa = true;
    this.texto_operacion = '';
    this.contactoEmpresaForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    this.StrContacto = 'Dar de Alta Nuevo Contacto';
  }

  VerContacto(data: number) {
    this.ClienteProspectoService.getContactoEmpresa(data).subscribe((resp) => {
      this.contactoEmpresaObj = resp;
      this.cat_nombresContacto=this.contactoEmpresaObj.map(valor=>({
        NombreCompletoCto:valor.des_nombre+' '+valor.des_apellidopaterno+' '+valor.des_apellidomaterno
      }))
      setTimeout(() => {
      }, 400);
    })
    this.dspVerContactoEmpresa = true;
  }

  EditarContacto(data: ContactoEmpresa, num_accion: number) {
    this.objCtoCte = data;
    this.contactoEmpresaForm.controls['id_contacto_empresa'].setValue(data.id_contacto_empresa);
    this.contactoEmpresaForm.controls['id_cte_prospecto'].setValue(data.id_cte_prospecto);
    this.contactoEmpresaForm.controls['correoContacto'].setValue(data.des_correocontacto);
    this.contactoEmpresaForm.controls['nombres'].setValue(data.des_nombre);
    this.contactoEmpresaForm.controls['aPaterno'].setValue(data.des_apellidopaterno);
    this.contactoEmpresaForm.controls['aMaterno'].setValue(data.des_apellidomaterno);
    this.contactoEmpresaForm.controls['cargo'].setValue(data.id_tipo_puesto);
    this.contactoEmpresaForm.controls['num_telefono'].setValue(data.num_telefono);
    this.contactoEmpresaForm.controls['area'].setValue(data.id_tipo_clasificacion);
    this.numero_operacion = num_accion;
    this.StrContacto = 'Editar Contacto';
    this.dspContactoEmpresa = true;
  }

  guardarInformacionContacto() {
    let data_enviar: ContactoEmpresa = {
      opcion: this.numero_operacion,
      id_contacto_empresa: this.numero_operacion == 1 ? 0 : this.contactoEmpresaForm.get('id_contacto_empresa')?.value,
      id_cte_prospecto: this.numero_operacion == 1 ? this.objCteProspecto.id_cte_prospecto : this.contactoEmpresaForm.get('id_cte_prospecto')?.value,
      des_correocontacto: this.contactoEmpresaForm.get('correoContacto')?.value,
      des_nombre: this.contactoEmpresaForm.get('nombres')?.value,
      des_apellidopaterno: this.contactoEmpresaForm.get('aPaterno')?.value,
      des_apellidomaterno: this.contactoEmpresaForm.get('aMaterno')?.value===null?'':this.contactoEmpresaForm.get('aMaterno')?.value,
      nom_puesto: this.contactoEmpresaForm.get('cargo')?.value,
      num_telefono: this.contactoEmpresaForm.get('num_telefono')?.value,
      nom_clasificacionpuesto: this.contactoEmpresaForm.get('area')?.value
    }
    if (this.numero_operacion == 1) {
      this.loading = true;
      this.ClienteProspectoService.saveContactoEmpresa(data_enviar).subscribe((resp) => {
        var resultado = resp;
        setTimeout(() => {
          this.getListadoClientesProspecto();
          this.message.add({ severity: 'success', summary: 'Contacto agregado con exito', detail: 'La informacion se almaceno de forma correcta' });
          this.contactoEmpresaForm.reset();
          this.cancelarVentanaModalContacto();
          this.loading = false;
        }, 1000);
      },
        (error) => {
          this.message.add({ severity: 'error', summary: 'Error en <Agregar Contacto>', detail: 'Contacte al Administrador del Sitio' });
        });
    }
    else {
      if (this.numero_operacion == 2) {
        this.ClienteProspectoService.editContacto(data_enviar).subscribe((resp) => {
          var resultado = resp;
          setTimeout(() => {
            this.getListadoClientesProspecto();
            this.VerContacto(data_enviar.id_cte_prospecto);
            this.message.add({ severity: 'success', summary: 'Edicion Exitosa', detail: 'la informacion se edito correctamente' });
            this.contactoEmpresaForm.reset();
            this.cancelarVentanaModalContacto();
          }, 800);
        })
      }
    }

  }



  EstatusLead(data: ClientesProspecto) {
    this.inicializarFechas();
    this.SubirArchivoForm.controls['CampoArchivo'].disable();
    this.idclienteprospecto = data.id_cte_prospecto;
    this.NomEmpresaGlobal = data.nom_empresa;
    this.ObtenerInformacionBrief();
    this.BriefForm.controls['nom_Cliente'].setValue(data.nom_empresa);
    let camp = this.tipoproyectos.find(obj => obj.id_proyecto === data.id_tipo_proyecto);
    let nom: string = ''
    this.BriefForm.controls['urlPortalComercial'].disable();
    this.BriefForm.controls['urlPortalOperativo'].disable();
    this.BriefForm.controls['nombreAplicacion'].disable();
    if (camp) {
      nom = camp.nombre;
    }

    this.BriefForm.controls['tipoProyecto'].setValue(nom);
    this.ClienteProspectoService.getContactoEmpresa(data.id_cte_prospecto).subscribe((resp) => {
      this.contactoEmpresaObj = resp;
      let con = this.contactoEmpresaObj[0].des_correocontacto;
      let tel = this.contactoEmpresaObj[0].num_telefono;
      this.BriefForm.controls['correoContacto'].setValue(con);
    })

    this.getMovimientosClientes(data.id_cte_prospecto)
    this.objetoCtesProspectoPasar[0] = data;
    this.otroMenu = this.ModeloEstatus.map(estat => {
      return { id: estat.id_orden, label: estat.estatus, readonly: true }
    })
    this.VerEstatus = true;
  }
  clear(table: Table) {
    table.clear();
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }
  IrAEstatus(data: ClientesProspecto) {
    this.inicializarFechas();
    this.SubirArchivoForm.controls['CampoArchivo'].disable();
    this.idclienteprospecto = data.id_cte_prospecto;
    this.NomEmpresaGlobal = data.nom_empresa;
    this.ObtenerInformacionBrief();
    this.BriefForm.controls['nom_Cliente'].setValue(data.nom_empresa);
    let camp = this.tipoproyectos.find(obj => obj.id_proyecto === data.id_tipo_proyecto);
    let nom: string = ''
   
    this.BriefForm.controls['urlPortalComercial'].disable();
    this.BriefForm.controls['urlPortalOperativo'].disable();
    this.BriefForm.controls['nombreAplicacion'].disable();
    if (camp) {
      nom = camp.nombre;
    }

    this.BriefForm.controls['tipoProyecto'].setValue(nom);
    this.ClienteProspectoService.getContactoEmpresa(data.id_cte_prospecto).subscribe((resp) => {
      this.contactoEmpresaObj = resp;
      let con = this.contactoEmpresaObj[0].des_correocontacto;
      this.BriefForm.controls['correoContacto'].setValue(con);
    })

    this.getMovimientosClientes(data.id_cte_prospecto)
    this.objetoCtesProspectoPasar[0] = data;
  }
  cancelarVentanaModal() {
    this.texto_operacion = '';
    this.dspNuevoCliente = false;
    this.ClienteProspectoForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
    this.ClienteProspectoForm.controls['fec_alta'].enable();
  }

  cancelarVentanaModalContacto() {
    this.texto_operacion = '';
    this.dspContactoEmpresa = false;
    this.contactoEmpresaForm.reset();
    this.resultado = {
      clv_estatus: 0,
      messageDetails: ''
    };
  }

  openNewWindow(pagina: string): void {
    window.open("//" + pagina, '_blank');
  }
}