import { INombreConfiguracion } from 'src/app/shared/models/NombreConfiguracion';
import IArea from 'src/app/shared/models/Area';
import IConfiguracionUnidades from 'src/app/shared/models/ConfiguracionUnidades';
import { IUsuarios } from './../../shared/models/usuarios';
import { ConfirmationService, ConfirmEventType, MessageService, MenuItem } from 'primeng/api';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/shared/services/http.service';
import ClientesProspecto from 'src/app/shared/models/ClientesProspecto';
import { Estatus } from 'src/app/shared/models/Estatus';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { IResultadoGeneral } from 'src/app/shared/models/IResultadoGeneral';
import { AuthService } from 'src/app/shared/services/auth.service';
//import TipoProyecto from 'src/app/shared/models/TipoProyecto';
//servicios
import { clienteprospecto } from 'src/app/shared/services/clienteprospecto.service';
import User from 'src/app/shared/models/user';
import { tipoProyecto } from 'src/app/shared/services/tipoProyecto.service';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import ITabuladorCostos from 'src/app/shared/models/TabuladorCostos';
import ITabuladorDiario from 'src/app/shared/models/TabuladorDiario';
import { tabuladorCostosService } from 'src/app/shared/services/tabuladorCostos.service';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router'
import TipoRuta from 'src/app/shared/models/TipoRuta';
import Estados_Ciudades from 'src/app/shared/models/Estados_Ciudades';
import { ITipoCliente } from 'src/app/shared/models/TipoCliente';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartComponent } from "ng-apexcharts";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import IExportar from 'src/app/shared/models/ExportarTabulador';
import { IEstatusPropuesta } from 'src/app/shared/models/EstatusPropuesta';
import { Observable, switchMap } from 'rxjs';
import ContactoEmpresa from 'src/app/shared/models/ContactoEmpresa';
import { IIimporteCasetaCiudad } from 'src/app/shared/models/ImporteCasetaCiudad';
import { FormBuilder } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';

interface Column {
  field:string;
  header: string;
}
Chart.register(...registerables, ChartDataLabels);
@Component({
  selector: 'app-tabulador-costos',
  templateUrl: './tabulador-costos.component.html',
  styleUrls: ['./tabulador-costos.component.scss']
})
export class TabuladorCostosComponent implements OnInit {
  tabuladorDiarioData: ITabuladorDiario[] = [];
  dspTabuladorDiario: boolean = false; 
  tablaExportar: IExportar[] = [];
  idruta: number = 0;
  data: any
  numeroExportar:string='';
  kampo:string='';
  seleccionExportar: any[] = [];
  datosRecibidos: any;
  TabuladorCostos: ITabuladorCostos[] = [];
  DatosClientesProspecto: ClientesProspecto[] = [];
  Obj_Cotizacion:any[]=[];
  idx_tabulador:number=0;
  valorNum:number=0;
  texto:string='Ver como Lista';
  configuracionesSeleccionadas: number[] = [];
  bntDescargarCotizacion:boolean=false;
  tablaRespuesta: any[] = [];
  tablaVacia: any[] = [];
  dspTablaRegreso: boolean = false;
  NombreEmpresaGlobal: string = '';
  idx_global:number=0;
  IdClienteGlobal: number = 0;
  cat_clientesProspecto: ClientesProspecto[] = [];
  loading: boolean = false;
  cols!: Column[];
  id_corridaglobal:number=0;
  dspTabulador: boolean = false;
  cat_ConfiguracionUnidades: IConfiguracionUnidades[] = [];
  estados: Estados_Ciudades[] = [];
  previousSelectedValues: any[] = [];
  setidGlobal: number = 0;
  setnomGlobal: string = '';
  costoGlobal: number = 0;
  dspGrafica: boolean = false;
  //cat_Rutas:TipoRuta[]=[];
  valorSeleccionado: number = 0;
  valorEstatus:number=0;
  lstNombreConfiguracion: string[] = [];
  cat_estatuspropuesta:IEstatusPropuesta[]=[];
  dspFrmCotizacion:boolean=false;
  importes: { [key: number]: number } = {};
  estatusPropuesta: any[] = [{
    value: 1,
    label: 'ENVIADO',
    icon: 'pi pi-check',
    bgColor: 'green'
  },
  {
    value: 0,
    label: 'NO ENVIADO',
    icon: 'pi pi-times',
    bgColor: 'orange',
  }]
  num_diasGlobal: number = 0;
  municipioscat: any[] = [];
  MunicipiosFiltrados: any[] = [];


  catalogo_TipoCliente: ITipoCliente[] = [{
    id_TipoCliente: 1,
    des_TipoCliente: 'Propio'
  },
  {
    id_TipoCliente: 2,
    des_TipoCliente: 'Comision de Terceros'
  }]
  isListView:boolean=false;
  TipoClienteSeleccionado: number = 0;

  dataLoaded = false;
  options: any;
  shouldShowHospedaje: boolean = false;
  TablaRespuestaEditar:any[]=[];
  usuario: User = {};
  municipios: Estados_Ciudades[] = [];
  color_gris_deshabilitadas: string = '#D9D9D9';
  @ViewChild('dt1') dt!: Table;
  selectedValue: number = 0;
  selectedvaluestring: string = '';
  valor: number = 0;
  dspEditarTAbulado:boolean=false;

  cat_Rutas: IArea[] = [{
    id_area: 1,
    nom_area: 'Local'
  },
  {
    id_area: 2,
    nom_area: 'Foranea'
  }
  ]


  tabuladorForm: FormGroup = new FormGroup({
    configuracion_Unidad: new FormControl<IConfiguracionUnidades | null>(null, [Validators.required]),
    Area: new FormControl(0, [Validators.required]),
    kilometros: new FormControl(0, [Validators.required]),
    hrs_carga_descarga: new FormControl(0, [Validators.required]),
    num_choferes: new FormControl(0, [Validators.required]),
    num_auxiliares: new FormControl(0, [Validators.required]),
    porcentaje_UTD: new FormControl(0, [Validators.required]),
    tipo_cliente: new FormControl('', [Validators.required]),
    km_Inicial: new FormControl(0, []),
    km_Final: new FormControl(0, []),
    sueldo_Driver: new FormControl(0, []),
    sueldo_Auxiliar: new FormControl(0, []),
    rH_diario: new FormControl(0, []),
    mto_Diario: new FormControl(0, []),
    monitoreo_Diario: new FormControl(0, []),
    operaciones: new FormControl(0, []),
    seguridadPatrimonial: new FormControl(0, []),
    comercial: new FormControl(0, []),
    origen: new FormControl(0, [Validators.required]),
    destino: new FormControl(0, [Validators.required]),
    escala: new FormControl(0, []),
    imp_peajeindividual_extr: new FormControl(0, []),
    imp_porcentajecancelado_extr: new FormControl(0, []),
    imp_descargaCompleta_extr: new FormControl(0, []),
    num_Personas_extr: new FormControl(0, []),
    imp_Persona_extr: new FormControl(0, []),
    imp_Subcontrato_extr: new FormControl(0, []),
    comision_cliente: new FormControl(0, [])
  })
  uniqueConfigurations: string[]=[];
  importesForms: FormGroup[] = [];
  importesForm: FormGroup[] = [];
  objImporteCaseta:IIimporteCasetaCiudad[]=[];


  EditarTabuladoForm:FormGroup = new FormGroup({
    configuracion_Unidad: new FormControl(0, [Validators.required]),
    Area: new FormControl(0, [Validators.required]),
    kilometros: new FormControl(1, []),
    hrs_carga_descarga: new FormControl(0, []),
    num_choferes: new FormControl(0, []),
    num_auxiliares: new FormControl(0, []),
    porcentaje_UTD: new FormControl(0, []),
    tipo_cliente: new FormControl('', []),
    km_Inicial: new FormControl(0, []),
    km_Final: new FormControl(0, []),
    sueldo_Driver: new FormControl(0, []),
    sueldo_Auxiliar: new FormControl(0, []),
    rH_diario: new FormControl(0, []),
    mto_Diario: new FormControl(0, []),
    monitoreo_Diario: new FormControl(0, []),
    operaciones: new FormControl(0, []),
    seguridadPatrimonial: new FormControl(0, []),
    comercial: new FormControl(0, []),
    origen: new FormControl(0, []),
    destino: new FormControl(0, []),
    escala: new FormControl(0, []),
    imp_peajeindividual_extr: new FormControl(0, []),
    imp_porcentajecancelado_extr: new FormControl(0, []),
    imp_descargaCompleta_extr: new FormControl(0, []),
    num_Personas_extr: new FormControl(0, []),
    imp_Persona_extr: new FormControl(0, []),
    imp_Subcontrato_extr: new FormControl(0, []),
    comision_cliente: new FormControl(0, [])
  })

  configuracionSeleccionada:number=0;
  datoseditartabulador:any[]=[];

  CotizacionForm: FormGroup = new FormGroup({
    nom_Contacto: new FormControl('', [Validators.required]),
    num_DiasOperativos: new FormControl(0, [Validators.required]),
    fecha_emision:new FormControl('',[Validators.required]),
    id_jornadaOperacion: new FormControl(0, [Validators.required]),
    hrs_EsperaDescarga: new FormControl(0, [Validators.required]),
    hrs_Advertencia:new FormControl(0,[Validators.required]),
    hrs_bloqueEstadia:new FormControl(0,[Validators.required]),
    num_porcentajeFleteFalso: new FormControl(0, [Validators.required]),
    num_porcentajeFleteRetorno: new FormControl(0, [Validators.required]),
    num_diasCredito: new FormControl(0, [Validators.required]),
    id_tipoEvento: new FormControl(0, [Validators.required]),
    num_VigenciaTarifaria: new FormControl(0, [Validators.required]),
    num_DiasValidez: new FormControl(0, [Validators.required]),
    num_cantidadrepartos:new FormControl(0,[Validators.required])
  })
  cat_nombresContacto:any[]=[{
    NombreCompletoCto:''
  }];
  cat_diasoperacion:any[]=[];
  cat_periodofacturacion:any[]=[];
  contactoEmpresaObj: ContactoEmpresa[] = [];
  constructor(private httpService: HttpService,
    private ClienteProspectoService: clienteprospecto,private fb: FormBuilder, private confirmationService: ConfirmationService, private message: MessageService, private auth: AuthService,
    private tipoproyectoservice: tipoProyecto, private tabuladorService: tabuladorCostosService, private primengConfig: PrimeNGConfig,private route: ActivatedRoute, private router: Router) {
    this.cargarInformacionUsuario();
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state;

    if (state && state['datos']) {
      const datosRecibidos = state['datos'];
      this.DatosClientesProspecto = datosRecibidos;
      this.NombreEmpresaGlobal = datosRecibidos.nom_empresa;
      this.IdClienteGlobal = datosRecibidos.id_cte_prospecto;
      localStorage.setItem('DatosClientesProspecto', JSON.stringify(datosRecibidos));
    } else {
      // Intentar cargar los datos desde localStorage si no se reciben en la navegación
      const savedDatos = localStorage.getItem('DatosClientesProspecto');
      if (savedDatos) {
        const datosRecibidos = JSON.parse(savedDatos);
        this.DatosClientesProspecto = datosRecibidos;
        this.NombreEmpresaGlobal = datosRecibidos.nom_empresa;
        this.IdClienteGlobal = datosRecibidos.id_cte_prospecto;
      }
    }
  }

  ngOnInit(): void {
    this.setProspecto();
    this.ObtenerTablaPrincipal();
    this.ObtenerConfiguracionUnidades();
    this.getListadoEstados();
    this.ObtenerMunicipios();
    this.ObtenerPropuestaEstatus();
    this.ObtenerOracionesJornada();
    this.ObtenerPeriodoFacturacion();
    this.primengConfig.setTranslation({
      // Configuración en español
      startsWith: 'Empieza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Termina con',
      equals: 'Igual a',
      notEquals: 'Diferente de',
      // Para los filtros de fecha
      dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
      monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
      monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
      today: 'Hoy',
      clear: 'Limpiar',
      // Otros filtros numéricos y generales
      lt: 'Menor que',
      lte: 'Menor o igual que',
      gt: 'Mayor que',
      gte: 'Mayor o igual que',
      is: 'Es',
      isNot: 'No es',
      before: 'Antes',
      after: 'Después',
      apply: 'Aplicar',
      matchAll: 'Coincidir con todo',
      matchAny: 'Coincidir con cualquiera',
      addRule: 'Agregar regla',
      removeRule: 'Eliminar regla',
      accept: 'Sí',
      reject: 'No',
    });
  }
  checkHospedajeColumn() {
    this.shouldShowHospedaje = this.TabuladorCostos.some(item => item.num_dias > 0);
  }
  guardarInformacionEditarTabulador(){
    this.confirmationService.confirm({
      message: 'Esta seguro que desea Guardar la Informacion ?',
      header: 'Confirmacion de Guardado',
      icon: 'pi pi-info-circle',
      accept: () => {
        let data_enviar = {
          idx:this.idx_global,
          id_config_unidad:this.objImporteCaseta[0].id_configuracion,
          imp_peaje:this.objImporteCaseta[0].importeCasetaConfiguracion,
          id_tiporuta: this.EditarTabuladoForm.get('Area')?.value,
          des_origen: this.EditarTabuladoForm.get('origen')?.value,
          des_destino: this.EditarTabuladoForm.get('destino')?.value,
          id_escala: this.EditarTabuladoForm.get('escala')?.value ?? 0,
          num_kilometros: this.EditarTabuladoForm.get('kilometros')?.value,
          horas_cargaydescarga: this.EditarTabuladoForm.get('hrs_carga_descarga')?.value,
          num_choferes: this.EditarTabuladoForm.get('num_choferes')?.value,
          num_auxiliares: this.EditarTabuladoForm.get('num_auxiliares')?.value,
          num_porcentajeutd: this.EditarTabuladoForm.get('porcentaje_UTD')?.value,
          id_tipo_cliente: this.EditarTabuladoForm.get('tipo_cliente')?.value,
          imp_terceros: this.TipoClienteSeleccionado == 2 ? this.EditarTabuladoForm.get('comision_cliente')?.value : 0,
          imp_peajeindividual_extr: this.EditarTabuladoForm.controls['imp_peajeindividual_extr']?.value === null ? 0 : this.EditarTabuladoForm.get('imp_peajeindividual_extr')?.value,
          imp_porcentajecancelado_extr: this.EditarTabuladoForm.controls['imp_porcentajecancelado_extr']?.value === null ? 0 : this.EditarTabuladoForm.get('imp_porcentajecancelado_extr')?.value,
          imp_descargaCompleta_extr: this.EditarTabuladoForm.controls['imp_descargaCompleta_extr']?.value === null ? 0 : this.EditarTabuladoForm.get('imp_descargaCompleta_extr')?.value,
          num_Personas_extr: this.EditarTabuladoForm.controls['num_Personas_extr']?.value === null ? 0 : this.EditarTabuladoForm.get('num_Personas_extr')?.value,
          imp_Persona_extr: this.EditarTabuladoForm.controls['imp_Persona_extr']?.value === null ? 0 : this.EditarTabuladoForm.get('imp_Persona_extr')?.value,
          imp_Subcontrato_extr: this.EditarTabuladoForm.controls['imp_Subcontrato_extr']?.value === null ? 0 : this.EditarTabuladoForm.get('imp_Subcontrato_extr')?.value,
          id_corrida:this.id_corridaglobal
        };
        this.tabuladorService.GetTabuladorEdit(data_enviar).subscribe((resp) => {
          this.TablaRespuestaEditar = resp;
          this.uniqueConfigurations = this.getUniqueConfigurations(this.TablaRespuestaEditar);
          
      this.cols = [
        { field: 'id_corrida', header: 'No. Tabulado' },
        { field: 'configuracion', header: 'Conf. Unidad' },
        { field: 'origen', header: 'Origen' },
        { field: 'destino', header: 'Destino' },
        { field: 'escala', header: 'Escala' },
        { field: 'num_dias', header: 'Numero de Dias' },
        { field: 'costo', header: 'Costo' },
        { field: 'num_porcentajeutd', header: 'Porcentaje UTD' },
        { field: 'imp_preciodeventa', header: 'Precio de Venta' },
        { field: 'imp_utilidad', header: 'Utilidad' },
        { field: 'imp_utilidadmensual', header: 'Utilidad Mensual' },
        { field: 'imp_sueldochofer', header: 'Sueldo Chofer' },
        { field: 'imp_sueldoaux', header: 'Sueldo Auxiliar' },
        { field: 'imp_combustible', header: 'Gasto Combustible' },
        { field: 'imp_comisioncombustible', header: 'Gasto Comision Combustible' },
        { field: 'imp_cel_gps', header: 'Gasto Celular GPS' },
        { field: 'imp_imss', header: 'IMSS' },
        { field: 'imp_hospedaje', header: 'Gasto Hospedaje' },
        { field: 'imp_viaticos', header: 'Gasto Viaticos' }
      ];
          setTimeout(() => {
            this.EditarTabuladoForm.reset();
            this.EditarTabuladoForm.controls['imp_porcentajecancelado_extr'].setValue(40);
            this.EditarTabuladoForm.controls['porcentaje_UTD'].setValue(35);
            this.EditarTabuladoForm.controls['hrs_carga_descarga'].setValue(1);
            this.configuracionesSeleccionadas = [];
            this.dspTablaRegreso = true;
            this.TipoClienteSeleccionado = 0;
          }, 400);
        })
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    });
  }
  GuardarEditarTabulado(data:any){
    let configu = this.cat_ConfiguracionUnidades.find(obj => obj.nombre === data.configuracion)
    let configvalor: number;
    if (configu) {
      if (configu.clave_configuracionUnidad) {
        configvalor = configu.clave_configuracionUnidad;
      }
    }

    let estado = this.municipioscat.find(obj => obj.municipio === data.origen);
    let id_estado: number = 0;
    if (estado) {
      if (estado.id) {
        id_estado = estado.id;
      } 
    }
    let muni = this.municipioscat.find(obj => obj.municipio === data.destino);
    let id_muni: number = 0;
    if (muni) {
      if (muni.id) {
        id_muni = muni.id;
      }
    }
    let esc = this.municipioscat.find(obj => obj.municipio === data.escala);
    let id_esc: number = 0;
    if (esc) {
      if (esc.id) {
        id_esc = esc.id;
      }
    }
    this.confirmationService.confirm({
      message: 'Estas Seguro que Deseas Guardar este Tabulado?',
      header: 'Confirmar',
      icon: 'pi pi-check-square',
      accept: () => {
        let data_enviar = {
          id_corrida: data.id_corrida,
          id_cte_prospecto: this.IdClienteGlobal,
          id_config_unidad: configvalor,
          id_origen: id_estado,
          id_destino: id_muni,
          id_escala: id_esc,
          costo: data.costo,
          imp_preciodeventa: data.imp_preciodeventa,
          imp_utilidad: data.imp_utilidad,
          imp_utilidadmensual: data.imp_utilidadmensual,
          imp_sueldochofer: data.imp_sueldochofer,
          imp_sueldoaux: data.imp_sueldoaux,
          imp_combustible: data.imp_combustible,
          imp_comisioncombustible: data.imp_comisioncombustible,
          imp_cel_gps: data.imp_cel_gps,
          imp_imss: data.imp_imss,
          imp_hospedaje: data.imp_hospedaje,
          imp_viaticos: data.imp_viaticos,
          cod_usuario: this.usuario.cod,
          num_dias: data.num_dias,
          id_tiporuta: data.id_tiporuta,
          num_kilometros: data.num_kilometros,
          imp_peaje: data.imp_peaje,
          horas_cargaydescarga: data.horas_cargaydescarga,
          num_choferes: data.num_choferes,
          num_auxiliares: data.num_auxiliares,
          num_porcentajeutd: data.num_porcentajeutd,
          id_tipo_cliente: data.id_tipo_cliente,
          imp_terceros: data.imp_terceros,
          imp_peajeindividual_extr: data.imp_peajeindividual_extr,
          imp_porcentajecancelado_extr: data.imp_porcentajecancelado_extr,
          imp_descargaCompleta_extr: data.imp_descargaCompleta_extr,
          num_Personas_extr: data.num_Personas_extr,
          imp_Persona_extr: data.imp_Persona_extr,
          imp_Subcontrato_extr: data.imp_Subcontrato_extr,
          imp_comisionvendedor: data.imp_comisionvendedor,
          imp_comision_tercero: data.imp_comision_tercero,
          imp_mantenimiento: data.imp_mantenimiento,
          imp_operaciones: data.imp_operaciones,
          imp_seguridadpatrimonial: data.imp_seguridadpatrimonial,
          imp_depreciacion: data.imp_depreciacion,
          imp_urea: data.imp_urea,
          idx:this.idx_global
        }
        this.tabuladorService.UpdateTabulador(data_enviar).subscribe((resp) => {
          var respuesta = resp;
          setTimeout(() => {
            this.cargarDatosTabla(data);
            this.ObtenerTablaPrincipal();
            this.message.add({ severity: 'success', summary: 'Tabulado Guardado Exitosamente', detail: 'Se ha Guardado' });
          }, 200);
        })
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    });
  }
/*
0
: 
horas_cargaydescarga
: 
1
id_config_unidad
: 
2
id_corrida
: 
23
id_destino
: 
4
id_origen
: 
2
id_tipo_cliente
: 
1
id_tiporuta
: 
2
idx
: 
33
imp_Persona_extr
: 
0
imp_Subcontrato_extr
: 
0
imp_descargaCompleta_extr
: 
0
imp_peaje
: 
300
imp_peajeindividual_extr
: 
0
imp_porcentajecancelado_extr
: 
0
imp_terceros
: 
0
num_Personas_extr
: 
0
num_auxiliares
: 
1
num_choferes
: 
1
num_kilometros
: 
1600
num_porcentajeutd
: 
35*/
Configi() {

  // Maneja el caso en el que configuracionSeleccionada es un solo valor
  const configuracion = this.configuracionSeleccionada;
  const configuracionUnidad = this.cat_ConfiguracionUnidades.find(c => c.idx_respaldo_confunidad === configuracion);

  // Crea un solo FormGroup en lugar de mapear sobre un array
  this.importesForm = [this.createConfiguracionFormGroup({ id: configuracion, nombre: configuracionUnidad?.nombre,importe:this.datoseditartabulador[0].imp_peaje})];

}

  EditarTabulado(data:any){
    this.loading=true;
    this.idx_global=data.idx;
    this.tabuladorService.getEditTabulador(data.idx).subscribe((resp)=>{
      this.datoseditartabulador=resp;
      
      setTimeout(() => {
        this.configuracionSeleccionada=this.datoseditartabulador[0].id_config_unidad;
        this.idruta=this.datoseditartabulador[0].id_tiporuta;
        this.id_corridaglobal=this.datoseditartabulador[0].id_corrida;
        this.EditarTabuladoForm.controls['configuracion_Unidad'].setValue(this.datoseditartabulador[0].id_config_unidad);
        this.Configi();
        this.EditarTabuladoForm.controls['Area'].setValue(this.datoseditartabulador[0].id_tiporuta);
        this.EditarTabuladoForm.controls['origen'].setValue(this.datoseditartabulador[0].id_origen);
        this.EditarTabuladoForm.controls['destino'].setValue(this.datoseditartabulador[0].id_destino);
        this.EditarTabuladoForm.controls['escala'].setValue(this.datoseditartabulador[0].id_escala);
        this.EditarTabuladoForm.controls['kilometros'].setValue(this.datoseditartabulador[0].num_kilometros);
        this.EditarTabuladoForm.controls['hrs_carga_descarga'].setValue(this.datoseditartabulador[0].horas_cargaydescarga);
        this.EditarTabuladoForm.controls['num_choferes'].setValue(this.datoseditartabulador[0].num_choferes);
        this.EditarTabuladoForm.controls['num_auxiliares'].setValue(this.datoseditartabulador[0].num_auxiliares);
        this.EditarTabuladoForm.controls['porcentaje_UTD'].setValue(this.datoseditartabulador[0].num_porcentajeutd);
        this.TipoClienteSeleccionado=this.datoseditartabulador[0].id_tipo_cliente;
        this.EditarTabuladoForm.controls['tipo_cliente'].setValue(this.datoseditartabulador[0].id_tipo_cliente);
        this.EditarTabuladoForm.controls['comision_cliente'].setValue(this.datoseditartabulador[0].imp_terceros);
        this.loading=false;
        this.dspEditarTAbulado=true;
      }, 200);
    })
    
  }
  

  guardarFrmCotizacion(){
    let data_enviar={
      nom_contacto:this.CotizacionForm.get('nom_Contacto')?.value,
      num_diasoperativos:this.CotizacionForm.get('num_DiasOperativos')?.value,
      fec_emision:this.CotizacionForm.get('fecha_emision')?.value,
      id_jornadaoperacion:this.CotizacionForm.get('id_jornadaOperacion')?.value,
      hrs_esperadescarga:this.CotizacionForm.get('hrs_EsperaDescarga')?.value,
      hrs_advertencia:this.CotizacionForm.get('hrs_Advertencia')?.value,
      hrs_bloqueestadia:this.CotizacionForm.get('hrs_bloqueEstadia')?.value,
      num_porcentajefletefalso:this.CotizacionForm.get('num_porcentajeFleteFalso')?.value,
      num_porcentajefleteretorno:this.CotizacionForm.get('num_porcentajeFleteRetorno')?.value,
      num_diasdecredito:this.CotizacionForm.get('num_diasCredito')?.value,
      id_tipoevento:this.CotizacionForm.get('id_tipoEvento')?.value,
      num_vigenciatarifaria:this.CotizacionForm.get('num_VigenciaTarifaria')?.value,
      num_diasvalidez:this.CotizacionForm.get('num_DiasValidez')?.value,
      idx_tabulador:this.idx_tabulador,
      num_cantidadrepartos:this.CotizacionForm.get('num_cantidadrepartos')?.value
    }
    this.tabuladorService.saveFormularioCotizacion(data_enviar).subscribe((resp)=>{
      var res=resp;
      setTimeout(() => {
        this.bntDescargarCotizacion=true;
        this.GetCotizacion();
        this.message.add({ key: 'msj', severity: 'success', summary: 'Guardado con Exito', detail: 'Operacion Realizada' });
      }, 250);
    })
  }

  ObtenerConfiguracionUnidades() {
    this.ClienteProspectoService.getConfiguracionUnidades().subscribe((resp) => {
      this.cat_ConfiguracionUnidades = resp
      setTimeout(() => {
      }, 300);
    })
  }
  

  ObtenerOracionesJornada(){
    this.tabuladorService.getOracionesJornada().subscribe((resp)=>{
      this.cat_diasoperacion=resp;
      setTimeout(() => {
        
      }, 200);
    })
  }
  ObtenerPeriodoFacturacion(){
    this.tabuladorService.getPeriodoFacturacion().subscribe((resp)=>{
      this.cat_periodofacturacion=resp;
      setTimeout(() => {
        
      }, 150);
    })
  }

  GetContactoConID(id:number){
    this.ClienteProspectoService.getContactoEmpresa(id).subscribe((resp) => {
      this.contactoEmpresaObj = resp;
      this.cat_nombresContacto=this.contactoEmpresaObj.map(valor=>({
        NombreCompletoCto:valor.des_nombre+' '+valor.des_apellidopaterno+' '+valor.des_apellidomaterno
      }))
      setTimeout(() => {
      }, 200);
    })
  }

  GetCotizacion(){
    this.tabuladorService.GetCotizacion(this.idx_tabulador).subscribe((resp)=>{
      this.Obj_Cotizacion=resp;
        setTimeout(() => {
          this.GetContactoConID(this.IdClienteGlobal);
          this.CotizacionForm.controls['nom_Contacto'].setValue(this.Obj_Cotizacion[0].nom_contacto);
          this.CotizacionForm.controls['num_DiasOperativos'].setValue(this.Obj_Cotizacion[0].num_diasoperativos);
          this.CotizacionForm.controls['id_jornadaOperacion'].setValue(Number(this.Obj_Cotizacion[0].id_jornadaoperacion));
          this.CotizacionForm.controls['hrs_EsperaDescarga'].setValue(this.Obj_Cotizacion[0].hrs_esperadescarga);
          this.CotizacionForm.controls['hrs_Advertencia'].setValue(this.Obj_Cotizacion[0].hrs_advertencia);
          this.CotizacionForm.controls['hrs_bloqueEstadia'].setValue(this.Obj_Cotizacion[0].hrs_bloqueestadia);
          this.CotizacionForm.controls['num_porcentajeFleteFalso'].setValue(this.Obj_Cotizacion[0].num_porcentajefletefalso);
          this.CotizacionForm.controls['num_porcentajeFleteRetorno'].setValue(this.Obj_Cotizacion[0].num_porcentajefleteretorno);
          this.CotizacionForm.controls['num_diasCredito'].setValue(this.Obj_Cotizacion[0].num_diasdecredito);
          this.CotizacionForm.controls['id_tipoEvento'].setValue(Number(this.Obj_Cotizacion[0].id_tipoevento));
          this.CotizacionForm.controls['num_VigenciaTarifaria'].setValue(this.Obj_Cotizacion[0].num_vigenciatarifaria);
          this.CotizacionForm.controls['num_DiasValidez'].setValue(this.Obj_Cotizacion[0].num_diasvalidez);
        }, 150);
    })
  }

  verTabuladorDiario(idxs: number): void {
    this.tabuladorService.GetDiarioTabulador(idxs).subscribe(
        (data: ITabuladorDiario[]) => {
            this.tabuladorDiarioData = data; 
            this.dspTabuladorDiario = true; 
        },
        (error) => {
            console.error('Error al obtener el tabulador diario:', error);
        }
    );
  }

  VerFrmCotizacion(data:any) {
    this.idx_tabulador=data.idx;
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var dateFormat = getYear + "-" + getMonth + "-" + getDay;
    let fecha = dateFormat;
    this.CotizacionForm.controls['fecha_emision'].setValue(fecha);
    this.tabuladorService.GetCotizacion(this.idx_tabulador).subscribe((resp)=>{
      this.Obj_Cotizacion=resp;
      if(resp.length>0){
        setTimeout(() => {
          this.GetContactoConID(this.IdClienteGlobal);
          this.CotizacionForm.controls['nom_Contacto'].setValue(this.Obj_Cotizacion[0].nom_contacto);
          this.CotizacionForm.controls['num_DiasOperativos'].setValue(this.Obj_Cotizacion[0].num_diasoperativos);
          this.CotizacionForm.controls['id_jornadaOperacion'].setValue(Number(this.Obj_Cotizacion[0].id_jornadaoperacion));
          this.CotizacionForm.controls['hrs_EsperaDescarga'].setValue(this.Obj_Cotizacion[0].hrs_esperadescarga);
          this.CotizacionForm.controls['hrs_Advertencia'].setValue(this.Obj_Cotizacion[0].hrs_advertencia);
          this.CotizacionForm.controls['hrs_bloqueEstadia'].setValue(this.Obj_Cotizacion[0].hrs_bloqueestadia);
          this.CotizacionForm.controls['num_porcentajeFleteFalso'].setValue(this.Obj_Cotizacion[0].num_porcentajefletefalso);
          this.CotizacionForm.controls['num_porcentajeFleteRetorno'].setValue(this.Obj_Cotizacion[0].num_porcentajefleteretorno);
          this.CotizacionForm.controls['num_diasCredito'].setValue(this.Obj_Cotizacion[0].num_diasdecredito);
          this.CotizacionForm.controls['id_tipoEvento'].setValue(Number(this.Obj_Cotizacion[0].id_tipoevento));
          this.CotizacionForm.controls['num_VigenciaTarifaria'].setValue(this.Obj_Cotizacion[0].num_vigenciatarifaria);
          this.CotizacionForm.controls['num_DiasValidez'].setValue(this.Obj_Cotizacion[0].num_diasvalidez);
          this.CotizacionForm.controls['num_cantidadrepartos'].setValue(this.Obj_Cotizacion[0].num_cantidadrepartos);
          this.bntDescargarCotizacion=true;
          this.dspFrmCotizacion=true;
        }, 200);
      }else{
        this.GetContactoConID(this.IdClienteGlobal);
        this.dspFrmCotizacion=true;
      }
    })
  }
  CerrarFrmCotizacion() {
    this.CotizacionForm.reset();
    this.Obj_Cotizacion=[];
    this.bntDescargarCotizacion=false;
    this.dspFrmCotizacion = false;
  }
  /*DescargarArchivoBrief(data: any) {
    let datos = {
      nombreArchivo: data.nom_archivo,
      nom_empresa: this.NomEmpresaGlobal
    }
    this.ClienteProspectoService.GetDescargarArchivo(datos).subscribe(
      (archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);
        link.setAttribute('download', data.nom_archivo); // El nombre del archivo se mantiene igual

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();

        link.remove();
      },
      error => {
        console.error('Error al obtener archivo:', error);
      }
    );
  } */
  GenerarCotizacion(){
    let idxs=this.idx_tabulador;
    this.tabuladorService.GenerateCotizacionPDF(idxs).subscribe(
      (archivo: Blob) => {
        // Generar la URL del archivo
        const archivoUrl = URL.createObjectURL(archivo);

        // Crear un enlace <a> para descargar el archivo
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', archivoUrl);

        // Agregar el enlace al cuerpo del documento y activar el clic
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      error => {
        console.error('Error al obtener archivo:', error);
      }
    )
  }

  setProspecto() {
    if (this.IdClienteGlobal == null && this.NombreEmpresaGlobal == null) {
      this.IdClienteGlobal = this.setidGlobal;
      this.NombreEmpresaGlobal = this.NombreEmpresaGlobal;
    } else {
      this.setidGlobal = this.IdClienteGlobal;
      this.setnomGlobal = this.NombreEmpresaGlobal;
    }
  }
  actualizarLabelTexto() {
    this.texto = this.isListView ? 'Ver como Tabla' : 'Ver como Lista';
  }

  ObtenerMunicipios() {
    this.tabuladorService.getMunicipios().subscribe((resp) => {
      this.municipioscat = resp;
      setTimeout(() => {
      }, 400);
    })
  }

  ObtenerPropuestaEstatus(){
    this.tabuladorService.getPropuestaEstatus().subscribe((resp)=>{
      this.cat_estatuspropuesta=resp;
      setTimeout(() => {
      }, 600);
    })
  }

  SetDropDown(data: ITabuladorCostos) {
    this.valorEstatus = data.id_estatuspropuesta;
  }
  

  ObtenerTablaPrincipal() {
    this.tabuladorService.getTabuladorPrincipal(this.IdClienteGlobal).subscribe((resp) => {
      this.TabuladorCostos = resp;
      this.checkHospedajeColumn();
      setTimeout(() => {
      }, 400);
    })
  }
  ModificarEstatus(data:ITabuladorCostos){

  }

  VerGrafica(data: ITabuladorCostos) {
    this.costoGlobal = data.costo;
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const originalData = [
      data.imp_comisioncombustible,
      data.imp_sueldochofer,
      data.imp_sueldoaux,
      data.imp_combustible,
      data.imp_cel_gps,
      data.imp_imss,
      data.imp_hospedaje,
      data.imp_viaticos,
      data.imp_depreciacion,
      data.imp_urea,
      data.imp_mantenimiento,
      data.imp_operaciones,
      data.imp_seguridadpatrimonial,
      data.imp_peaje,
      data.imp_comisionvendedor
    ].map(value => value || 0);
    const totalOriginal = originalData.reduce((sum, value) => sum + value, 0);

    const normalizedData = totalOriginal > 0 ? originalData.map(value => (value / totalOriginal) * data.costo) : originalData.map(() => 0);

    this.data = {
      labels: [
        'Comision Combustible: $' + normalizedData[0].toFixed(2),
        'Sueldo Chofer: $' + normalizedData[1].toFixed(2),
        'Sueldo Auxiliar: $' + normalizedData[2].toFixed(2),
        'Combustible: $' + normalizedData[3].toFixed(2),
        'Importe Celular GPS: $' + normalizedData[4].toFixed(2),
        'IMSS: $' + normalizedData[5].toFixed(2),
        'Hospedaje: $' + normalizedData[6].toFixed(2),
        'Viaticos: $' + normalizedData[7].toFixed(2),
        'Depreciacion: $' + normalizedData[8].toFixed(2),
        'Urea: $' + normalizedData[9].toFixed(2),
        'Mantenimiento: $' + normalizedData[10].toFixed(2),
        'Operaciones: $' + normalizedData[11].toFixed(2),
        'Seguridad Patrimonial: $' + normalizedData[12].toFixed(2),
        'Peaje: $' + normalizedData[13].toFixed(2),
        'Comision del Vendedor: $' + normalizedData[14].toFixed(2)
      ],
      datasets: [
        {
          data: normalizedData,
          backgroundColor: [
            documentStyle.getPropertyValue('--yellow-500'),
            documentStyle.getPropertyValue('--green-500'),
            documentStyle.getPropertyValue('--orange-500'),
            documentStyle.getPropertyValue('--gray-500'),
            documentStyle.getPropertyValue('--purple-500'),
            documentStyle.getPropertyValue('--black-500'),
            documentStyle.getPropertyValue('--pink-500'),
            documentStyle.getPropertyValue('--indigo-500'),
            documentStyle.getPropertyValue('--purple-300'),
            documentStyle.getPropertyValue('--green-300'),
            documentStyle.getPropertyValue('--red-300'),
            documentStyle.getPropertyValue('--brown-500'),
            documentStyle.getPropertyValue('--yellow-300'),
            documentStyle.getPropertyValue('--blue-400')
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue('--yellow-400'),
            documentStyle.getPropertyValue('--green-400'),
            documentStyle.getPropertyValue('--orange-400'),
            documentStyle.getPropertyValue('--gray-400'),
            documentStyle.getPropertyValue('--purple-400'),
            documentStyle.getPropertyValue('--black-400'),
            documentStyle.getPropertyValue('--pink-400'),
            documentStyle.getPropertyValue('--indigo-400'),
            documentStyle.getPropertyValue('--purple-400'),
            documentStyle.getPropertyValue('--green-400'),
            documentStyle.getPropertyValue('--red-400'),
            documentStyle.getPropertyValue('--brown-400'),
            documentStyle.getPropertyValue('--yellow-400'),
            documentStyle.getPropertyValue('--blue-500')
          ]
        }
      ]
    };

    this.options = {
      responsive: true,
      cutout: '65%',
      plugins: {
        datalabels: {
          display: false
        },
        legend: {
          display: true,
          position: 'right',
          labels: {
            color: textColor,
            font: {
              size: 14,
              weight: 'bold'
            }
          }
        },
        tooltip: {
          enabled: true,
          mode: 'index',
          intersect: false,
          callbacks: {
            label: function (context: any) {
              let label = context.label || '';
              if (label) {
                label += ': ';
              }
              if (context.raw !== null) {
                label = context.label;
              }
              return label;
            }
          }
        }
      },
      animation: {
        display: true,
        enabled: true,
        animateScale: true,
        animateRotate: true
      }
    };
    this.dspGrafica = true;
  }
  
  public TraspasarTabla(data: any) {
    this.tablaExportar = [];
    let obj: any;
    obj = this.cat_Rutas.find(objeto => objeto.id_area === data.id_tiporuta);
    let kampo: string;
    if (obj) {
      if (obj.nom_area) {
        kampo = obj.nom_area
        this.tablaExportar.push({
          Unidad: data.config_unidad,
          Origen: data.des_origen,
          Destino: data.des_destino,
          Escala: data.des_escala,
          Tipo_Ruta: kampo,
          Km: data.num_kilometros,
          Peaje: data.imp_peaje,
          Carg_Desc: data.num_horas,
          Operadores: data.num_choferes,
          Auxiliares: data.num_auxiliares,
          UTD: data.num_porcentajeutd,
          Dias: data.num_dias,
          Costo: data.costo,
          PDV: data.imp_preciodeventa,
          Utilidad: data.imp_utilidad,
          Comision_a_Terceros: data.imp_comision_tercero,
          Sueldo: data.imp_sueldochofer,
          Sueldo_Aux: data.imp_sueldoaux,
          Combustible: data.imp_combustible,
          C_Combustible: data.imp_comisioncombustible,
          Urea: data.imp_urea,
          Cel_Gps: data.imp_cel_gps,
          CS_PR: data.imp_imss,
          Depreciacion: data.imp_depreciacion,
          MMTO: data.imp_mantenimiento,
          Hospedaje: data.imp_hospedaje,
          Viaticos: data.imp_viaticos,
          Seguridad_Patrimonial:data.imp_seguridadpatrimonial,
          Operaciones:data.imp_operaciones
        })
      }
    } 
    return this.tablaExportar
  }
  public TraspasarTablaobj(data: any[]):any[] {
    this.tablaExportar = [];
    this.tablaExportar = data.map(element => {
      // Encuentra el objeto en cat_Rutas que coincide con id_tiporuta del elemento
      let found = this.cat_Rutas.find(objeto => objeto.id_area === element.id_tiporuta);
      if(found){
        if(found.nom_area){
          this.kampo=found.nom_area;
        }
      }

      // Construye el nuevo objeto con los valores transformados
      return {
        Unidad: element.config_unidad,
        Origen: element.des_origen,
        Destino: element.des_destino,
        Escala: element.des_escala,
        Tipo_Ruta: this.kampo,
        Km: element.num_kilometros,
        Peaje: element.imp_peaje,
        Carg_Desc: element.num_horas,
        Operadores: element.num_choferes,
        Auxiliares: element.num_auxiliares,
        UTD: element.num_porcentajeutd,
        Dias: element.num_dias,
        Costo: element.costo,
        PDV: element.imp_preciodeventa,
        Utilidad: element.imp_utilidad,
        Comision_a_Terceros: element.imp_comision_tercero,
        Sueldo: element.imp_sueldochofer,
        Sueldo_Aux: element.imp_sueldoaux,
        Combustible: element.imp_combustible,
        C_Combustible: element.imp_comisioncombustible,
        Urea: element.imp_urea,
        Cel_Gps: element.imp_cel_gps,
        CS_PR: element.imp_imss,
        Depreciacion: element.imp_depreciacion,
        MMTO: element.imp_mantenimiento,
        Hospedaje: element.imp_hospedaje,
        Viaticos: element.imp_viaticos,
        Seguridad_Patrimonial: element.imp_seguridadpatrimonial,
        Operaciones: element.imp_operaciones
      };
    });
    return this.tablaExportar
  }

  onEstatusPropuestaChange(event: any, data: any) {
    // Verifica si el checkbox está activado o desactivado
    let valor = event.target.checked ? 1 : 0;

    // Si el valor seleccionado ya es el mismo, no hacemos nada
    if (data.clv_enviado === valor) {
        return;
    }

    let obj = this.estatusPropuesta.find(obj => obj.value === valor);

    // Si el estatus está por cambiar a "ENVIADO"
    if (obj && obj.label && valor === 1) {
        // Muestra la confirmación solo si el valor es diferente y va a cambiar a ENVIADO
        this.confirmationService.confirm({
            message: '¿Estás seguro que deseas cambiar el estatus de este tabulado a <b>ENVIADO</b>?',
            header: 'Confirmar - Una vez enviado, no se podrá modificar',
            icon: 'pi pi-check-square',
            accept: () => {
                let data_enviar = { idx: data.idx };

                // Mantener el switch visible
                data.clv_enviado = 0; // Establecer temporalmente a no enviado para mantener el switch visible

                this.tabuladorService.updateEstatus(data_enviar).pipe(
                    switchMap(respSaveInfo => {
                        return new Observable(observer => {
                            let objetopasar = {
                                id_cte_prospecto: data.id_cte_prospecto,
                                id_estatus: 5
                            };
                            // Guarda el movimiento de estatus
                            this.ClienteProspectoService.saveMovimientoEstatus(objetopasar).subscribe(() => {
                                setTimeout(() => {
                                    observer.next();
                                    observer.complete();
                                }, 300);
                            });
                        });
                    })
                ).subscribe(() => {
                    // Después de un pequeño retraso, cambia realmente el estatus
                    setTimeout(() => {
                        data.clv_enviado = 1; // Cambia el estatus a enviado
                        this.message.add({
                            key: 'msj',
                            severity: 'success',
                            summary: 'Estatus Modificado',
                            detail: 'Estatus modificado con éxito'
                        });
                        this.ObtenerTablaPrincipal(); // Actualiza la tabla
                    }, 300); // Ajusta el tiempo según lo necesites
                });
            },
            reject: (type: any) => {
                // Si se cancela, reinicia el estado del switch
                switch (type) {
                    case ConfirmEventType.REJECT:
                    case ConfirmEventType.CANCEL:
                        this.TabuladorCostos = [...this.TabuladorCostos];
                        this.message.add({
                            key: 'msj',
                            severity: 'warn',
                            summary: 'Cancelado',
                            detail: 'Operación cancelada por el usuario'
                        });
                        this.ObtenerTablaPrincipal();
                        break;
                }
            }
        });
    }
}

CerrarGrafica(){
  this.dspGrafica=false;
}

  GenerarTodoAExcel() {
    if (this.TabuladorCostos.length === 0) {
      return this.message.add({ key: 'msj', severity: 'warn', summary: 'No Existen Tabulados', detail: 'No Existen Tabulados para Exportar' });
    }
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.TraspasarTablaobj(this.TabuladorCostos));
    // Aplicar estilos a los encabezados (primera fila)
    const range = XLSX.utils.decode_range(worksheet['!ref']!);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: range.s.r };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (!worksheet[cellRef]) continue;

      worksheet[cellRef].s = {
        font: {
          name: 'Arial',
          sz: 12,
          bold: true,
          color: { rgb: "FFFFFFFF" } // Texto blanco
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FF0000FF" } // Fondo azul
        },
        border: {
          top: { style: "thin", color: { rgb: "FF000000" } },
          bottom: { style: "thin", color: { rgb: "FF000000" } },
          left: { style: "thin", color: { rgb: "FF000000" } },
          right: { style: "thin", color: { rgb: "FF000000" } }
        },
        alignment: {
          vertical: "center",
          horizontal: "center"
        }
      };
    }

    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    //var formattedDate = new Date().toLocaleString('es-MX', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var fecha_registro_momento = getYear + "-" + getMonth + "-" + getDay;
    this.saveExcelFile(excelBuffer, 'Tabulador_de_Costos_Completo_' + fecha_registro_momento + '');
  }

  GenerarExcel(data: any) {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet((this.TraspasarTabla(data)));
    // Aplicar estilos a los encabezados (primera fila)
    const range = XLSX.utils.decode_range(worksheet['!ref']!);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = { c: C, r: range.s.r };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      if (!worksheet[cellRef]) continue;

      worksheet[cellRef].s = {
        font: {
          name: 'Arial',
          sz: 12,
          bold: true,
          color: { rgb: "FFFFFFFF" } // Texto blanco
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FF0000FF" } // Fondo azul
        },
        border: {
          top: { style: "thin", color: { rgb: "FF000000" } },
          bottom: { style: "thin", color: { rgb: "FF000000" } },
          left: { style: "thin", color: { rgb: "FF000000" } },
          right: { style: "thin", color: { rgb: "FF000000" } }
        },
        alignment: {
          vertical: "center",
          horizontal: "center"
        }
      };
    }

    const workbook: XLSX.WorkBook = {
      Sheets: { 'data': worksheet },
      SheetNames: ['data']
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    //var formattedDate = new Date().toLocaleString('es-MX', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
    var getYear = new Date().toLocaleDateString('es-MX', { year: 'numeric' });
    var getMonth = new Date().toLocaleDateString('es-MX', { month: '2-digit' });
    var getDay = new Date().toLocaleDateString('es-MX', { day: '2-digit' });
    var fecha_registro_momento = getYear + "-" + getMonth + "-" + getDay;
    this.saveExcelFile(excelBuffer, 'Tabulador_de_Costos_' + this.NombreEmpresaGlobal + '_' + data.des_origen + '/' + data.des_destino + '_' + data.config_unidad + '_' + fecha_registro_momento + '');
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, `${fileName}.xlsx`);

  }
  getFiltradoMunicipios(event: any) {
    this.selectedValue = event.value;
    this.valor = event.value;
    this.tabuladorService.getMunicipiosFiltrados(this.selectedValue).subscribe((resp) => {
      this.MunicipiosFiltrados = resp;
      setTimeout(() => {

      }, 250);
    })
  }
  getListadoEstados() {
    this.ClienteProspectoService.getEstadosMunicipios().subscribe((resp) => {
      this.estados = resp;
      setTimeout(() => {
      }, 1000);
    })
  }

  getListadoMunicipios(event: any) {
    this.selectedValue = event.value;
    this.valor = event.value;
    this.ClienteProspectoService.getMunicipios(this.selectedValue).subscribe((resp) => {
      this.municipios = resp;
      setTimeout(() => {
      }, 1000);
    })
  }

  /*ObtenerTablaTabulador() {
    this.tabuladorService.getTabulador(this.IdClienteGlobal).subscribe((resp) => {
      this.TabuladorCostos = resp
      setTimeout(() => {
      }, 500);
    })
  }*/

  cargarInformacionUsuario() {
    this.usuario = this.auth.user;
  }

  obtenerDatos() {

  }

  ObtenerClientesProspecto() {
    this.ClienteProspectoService.getClientesProspecto().subscribe((resp) => {
      this.cat_clientesProspecto = resp;
      setTimeout(() => {
      }, 800);
    })
  }

  Tabular() {
    this.tabuladorForm.get('Area')?.enable();
    this.tabuladorForm.get('origen')?.enable();
    this.tabuladorForm.get('destino')?.enable();
    this.tabuladorForm.get('escala')?.enable();
    this.tabuladorForm.get('kilometros')?.disable();
    this.tabuladorForm.get('peaje')?.disable();
    this.tabuladorForm.get('num_choferes')?.disable();
    this.tabuladorForm.get('num_auxiliares')?.disable();
    this.tabuladorForm.get('tipo_cliente')?.disable();
    this.tabuladorForm.get('porcentaje_UTD')?.disable();
    this.tabuladorForm.get('hrs_carga_descarga')?.disable();
    this.tabuladorForm.get('imp_peajeindividual_extr')?.disable();
    this.tabuladorForm.get('imp_porcentajecancelado_extr')?.disable();
    this.tabuladorForm.get('imp_descargaCompleta_extr')?.disable();
    this.tabuladorForm.get('num_Personas_extr')?.disable();
    this.tabuladorForm.get('imp_Persona_extr')?.disable();
    this.tabuladorForm.get('imp_Subcontrato_extr')?.disable();
    this.tabuladorForm.controls['imp_porcentajecancelado_extr'].setValue(40);
    this.tabuladorForm.controls['porcentaje_UTD'].setValue(35);
    this.tabuladorForm.controls['hrs_carga_descarga'].setValue(8);
    this.tabuladorForm.controls['tipo_cliente'].setValue(1);
    this.tabuladorForm.controls['num_choferes'].setValue(1);
    this.tabuladorForm.controls['num_auxiliares'].setValue(0);
    this.tabuladorForm.controls['comision_cliente'].setValue(0);
    this.tabuladorForm.controls['kilometros'].setValue(1);
    this.dspTabulador = true;
  }

  openNewWindow(pagina: string): void {
    window.open("//" + pagina, '_blank');
  }

  clear(table: Table) {
    table.clear();
  }
  GenerarNuevoTabulado() {
    this.tabuladorForm.reset();
    this.objImporteCaseta = [];
    this.importesForms = [];
    this.tablaRespuesta = [...this.tablaVacia];
    this.uniqueConfigurations = [...this.tablaVacia];
    this.configuracionesSeleccionadas = [];
    this.tabuladorForm.get('Area')?.enable();
    this.tabuladorForm.get('origen')?.enable();
    this.tabuladorForm.get('destino')?.enable();
    this.tabuladorForm.get('escala')?.enable();
    this.tabuladorForm.get('kilometros')?.disable();
    this.tabuladorForm.get('peaje')?.disable();
    this.tabuladorForm.get('num_choferes')?.disable();
    this.tabuladorForm.get('num_auxiliares')?.disable();
    this.tabuladorForm.get('tipo_cliente')?.disable();
    this.tabuladorForm.get('porcentaje_UTD')?.disable();
    this.tabuladorForm.get('hrs_carga_descarga')?.disable();
    this.tabuladorForm.get('imp_peajeindividual_extr')?.disable();
    this.tabuladorForm.get('imp_porcentajecancelado_extr')?.disable();
    this.tabuladorForm.get('imp_descargaCompleta_extr')?.disable();
    this.tabuladorForm.get('num_Personas_extr')?.disable();
    this.tabuladorForm.get('imp_Persona_extr')?.disable();
    this.tabuladorForm.get('imp_Subcontrato_extr')?.disable();
    this.tabuladorForm.controls['imp_porcentajecancelado_extr'].setValue(40);
    this.tabuladorForm.controls['porcentaje_UTD'].setValue(35);
    this.tabuladorForm.controls['hrs_carga_descarga'].setValue(8);
    this.tabuladorForm.controls['tipo_cliente'].setValue(1);
    this.tabuladorForm.controls['num_choferes'].setValue(1);
    this.tabuladorForm.controls['num_auxiliares'].setValue(0);
    this.tabuladorForm.controls['comision_cliente'].setValue(0);
    this.tabuladorForm.controls['kilometros'].setValue(1);
    this.dspTablaRegreso = false;
  }
  GuardarTabulado(data: any) {
    let configu = this.cat_ConfiguracionUnidades.find(obj => obj.nombre === data.configuracion)
    let configvalor: number;
    if (configu) {
      if (configu.clave_configuracionUnidad) {
        configvalor = configu.clave_configuracionUnidad;
      }
    }
   
    let estado = this.municipioscat.find(obj => obj.id === data.id_origen);
    let id_estado: number = 0;
    if (estado) {
      if (estado.id) {
        id_estado = estado.id;
      }
    }
    let muni = this.municipioscat.find(obj => obj.id === data.id_destino);
    let id_muni: number = 0;
    if (muni) {
      if (muni.id) {
        id_muni = muni.id;
      }
    }
    let esc = this.municipioscat.find(obj => obj.id === data.id_escala);
    let id_esc: number = 0;
    if (esc) {
      if (esc.id) {
        id_esc = esc.id;
      }
    }
    this.confirmationService.confirm({
      message: 'Estas Seguro que Deseas Guardar este Tabulado?',
      header: 'Confirmar',
      icon: 'pi pi-check-square',
      accept: () => {
        let data_enviar = {
          id_corrida: data.id_corrida,
          id_cte_prospecto: this.IdClienteGlobal,
          id_config_unidad: configvalor,
          id_origen: id_estado,
          id_destino: id_muni,
          id_escala: id_esc,
          costo: data.costo,
          imp_preciodeventa: data.imp_preciodeventa,
          imp_utilidad: data.imp_utilidad,
          imp_utilidadmensual: data.imp_utilidadmensual,
          imp_sueldochofer: data.imp_sueldochofer,
          imp_sueldoaux: data.imp_sueldoaux,
          imp_combustible: data.imp_combustible,
          imp_comisioncombustible: data.imp_comisioncombustible,
          imp_cel_gps: data.imp_cel_gps,
          imp_imss: data.imp_imss,
          imp_hospedaje: data.imp_hospedaje,
          imp_viaticos: data.imp_viaticos,
          cod_usuario: this.usuario.cod,
          num_dias: data.num_dias,
          id_tiporuta: data.id_tiporuta,
          num_kilometros: data.num_kilometros,
          imp_peaje: data.imp_peaje,
          horas_cargaydescarga: data.horas_cargaydescarga,
          num_choferes: data.num_choferes,
          num_auxiliares: data.num_auxiliares,
          num_porcentajeutd: data.num_porcentajeutd,
          id_tipo_cliente: data.id_tipo_cliente,
          imp_terceros: data.imp_terceros,
          imp_peajeindividual_extr: data.imp_peajeindividual_extr,
          imp_porcentajecancelado_extr: data.imp_porcentajecancelado_extr,
          imp_descargaCompleta_extr: data.imp_descargaCompleta_extr,
          num_Personas_extr: data.num_Personas_extr,
          imp_Persona_extr: data.imp_Persona_extr,
          imp_Subcontrato_extr: data.imp_Subcontrato_extr,
          imp_comisionvendedor: data.imp_comisionvendedor,
          imp_comision_tercero: data.imp_comision_tercero,
          imp_mantenimiento: data.imp_mantenimiento,
          imp_operaciones: data.imp_operaciones,
          imp_seguridadpatrimonial: data.imp_seguridadpatrimonial,
          imp_depreciacion: data.imp_depreciacion,
          imp_urea: data.imp_urea,
          id_estatuspropuesta:2
        }
        this.tabuladorService.saveTabulador(data_enviar).subscribe((resp) => {
          var respuesta = resp;
          setTimeout(() => {
            this.cargarDatosTabla(data);
            this.ObtenerTablaPrincipal();
            this.message.add({ severity: 'success', summary: 'Tabulado Guardado Exitosamente', detail: 'Se ha Guardado' });
          }, 200);
        })
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    });
  }

  cargarDatosTabla(data: any) {
    this.tablaRespuesta = this.tablaRespuesta.filter(element => element.configuracion !== data.configuracion);
    this.tablaRespuesta = [...this.tablaRespuesta];
  }
  guardarInformacionTabulador() {
    const TipoConfiguracion = this.tabuladorForm.get('configuracion_Unidad')?.value;

    if (!TipoConfiguracion || TipoConfiguracion.length === 0) {
      return this.message.add({ severity: 'danger', summary: 'Error', detail: 'Debe seleccionar al menos un usuario' });
    }
    let cadena: string = '';
    if (this.configuracionesSeleccionadas.length > 0) {
      this.configuracionesSeleccionadas.map((val) => {
        let configuracion: any = {
          clave_configuracionUnidad: val.toString()
        };
        configuracion.clave_configuracionUnidad = val.toString();
        cadena += val + ',';
        this.lstNombreConfiguracion.push(configuracion);
      });
    }
    this.confirmationService.confirm({
      message: 'Esta seguro que desea Guardar la Informacion ?',
      header: 'Confirmacion de Guardado',
      icon: 'pi pi-info-circle',
      accept: () => {
        let data_enviar = {
          unidadCosto: this.objImporteCaseta,
          id_tiporuta: this.tabuladorForm.get('Area')?.value,
          des_origen: this.tabuladorForm.get('origen')?.value,
          des_destino: this.tabuladorForm.get('destino')?.value,
          des_escala: this.tabuladorForm.get('escala')?.value ?? 0,
          num_kilometros: this.tabuladorForm.get('kilometros')?.value,
          horas_cargaydescarga: this.tabuladorForm.get('hrs_carga_descarga')?.value,
          num_choferes: this.tabuladorForm.get('num_choferes')?.value,
          num_auxiliares: this.tabuladorForm.get('num_auxiliares')?.value,
          num_porcentajeutd: this.tabuladorForm.get('porcentaje_UTD')?.value,
          id_tipo_cliente: this.tabuladorForm.get('tipo_cliente')?.value,
          imp_terceros: this.TipoClienteSeleccionado == 2 ? this.tabuladorForm.get('comision_cliente')?.value : 0,
          imp_peajeindividual_extr: this.tabuladorForm.controls['imp_peajeindividual_extr'].disabled === true ? 0 : this.tabuladorForm.get('imp_peajeindividual_extr')?.value,
          imp_porcentajecancelado_extr: this.tabuladorForm.controls['imp_porcentajecancelado_extr'].disabled === true ? 0 : this.tabuladorForm.get('imp_porcentajecancelado_extr')?.value,
          imp_descargaCompleta_extr: this.tabuladorForm.controls['imp_descargaCompleta_extr'].disabled === true ? 0 : this.tabuladorForm.get('imp_descargaCompleta_extr')?.value,
          num_Personas_extr: this.tabuladorForm.controls['num_Personas_extr'].disabled === true ? 0 : this.tabuladorForm.get('num_Personas_extr')?.value,
          imp_Persona_extr: this.tabuladorForm.controls['imp_Persona_extr'].disabled === true ? 0 : this.tabuladorForm.get('imp_Persona_extr')?.value,
          imp_Subcontrato_extr: this.tabuladorForm.controls['imp_Subcontrato_extr'].disabled === true ? 0 : this.tabuladorForm.get('imp_Subcontrato_extr')?.value,

        };
        this.tabuladorService.getTabulador(data_enviar).subscribe((resp) => {
          this.tablaRespuesta = resp;
          this.uniqueConfigurations = this.getUniqueConfigurations(this.tablaRespuesta);
          
      this.cols = [
        { field: 'id_corrida', header: 'No. Tabulado' },
        { field: 'configuracion', header: 'Conf. Unidad' },
        { field: 'origen', header: 'Origen' },
        { field: 'destino', header: 'Destino' },
        { field: 'escala', header: 'Escala' },
        { field: 'num_dias', header: 'Numero de Dias' },
        { field: 'costo', header: 'Costo' },
        { field: 'num_porcentajeutd', header: 'Porcentaje UTD' },
        { field: 'imp_preciodeventa', header: 'Precio de Venta' },
        { field: 'imp_utilidad', header: 'Utilidad' },
        { field: 'imp_utilidadmensual', header: 'Utilidad Mensual' },
        { field: 'imp_sueldochofer', header: 'Sueldo Chofer' },
        { field: 'imp_sueldoaux', header: 'Sueldo Auxiliar' },
        { field: 'imp_combustible', header: 'Gasto Combustible' },
        { field: 'imp_comisioncombustible', header: 'Gasto Comision Combustible' },
        { field: 'imp_cel_gps', header: 'Gasto Celular GPS' },
        { field: 'imp_imss', header: 'IMSS' },
        { field: 'imp_hospedaje', header: 'Gasto Hospedaje' },
        { field: 'imp_viaticos', header: 'Gasto Viaticos' }
      ];
          setTimeout(() => {
            this.tabuladorForm.reset();
            this.tabuladorForm.controls['imp_porcentajecancelado_extr'].setValue(40);
            this.tabuladorForm.controls['porcentaje_UTD'].setValue(35);
            this.tabuladorForm.controls['hrs_carga_descarga'].setValue(1);
            this.configuracionesSeleccionadas = [];
            this.dspTablaRegreso = true;
            this.TipoClienteSeleccionado = 0;
          }, 400);
        })
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
          case ConfirmEventType.CANCEL:
            this.message.add({ key: 'msj', severity: 'warn', summary: 'Cancelado', detail: 'Operacion cancelada por usuario' });
            break;
        }
      }
    });
  }
  getUniqueConfigurations(data: any[]): string[] {
    const configurations = data.map(item => item.configuracion);
    return [...new Set(configurations)];
  }

  Individual() {
    if (this.tabuladorForm.controls['imp_peajeindividual_extr'].disabled === true) {
      this.tabuladorForm.controls['imp_peajeindividual_extr'].enable();
    } else {
      this.tabuladorForm.controls['imp_peajeindividual_extr'].disable();
    }
  }
  Cancelado() {
    if (this.tabuladorForm.controls['imp_porcentajecancelado_extr'].disabled === true) {
      this.tabuladorForm.controls['imp_porcentajecancelado_extr'].enable();
    } else {
      this.tabuladorForm.controls['imp_porcentajecancelado_extr'].disable();
    }
  }
  DescargaCompleta() {
    if (this.tabuladorForm.controls['imp_descargaCompleta_extr'].disabled === true) {
      this.tabuladorForm.controls['imp_descargaCompleta_extr'].enable();
    } else {
      this.tabuladorForm.controls['imp_descargaCompleta_extr'].disable();
    }
  }
  PersonaHappy() {
    if (this.tabuladorForm.controls['num_Personas_extr'].disabled === true) {
      this.tabuladorForm.controls['num_Personas_extr'].enable();
    } else {
      this.tabuladorForm.controls['num_Personas_extr'].disable();
    }

    if (this.tabuladorForm.controls['imp_Persona_extr'].disabled === true) {
      this.tabuladorForm.controls['imp_Persona_extr'].enable();
    } else {
      this.tabuladorForm.controls['imp_Persona_extr'].disable();
    }
  }
  subcontratado() {
    if (this.tabuladorForm.controls['imp_Subcontrato_extr'].disabled === true) {
      this.tabuladorForm.controls['imp_Subcontrato_extr'].enable();
    } else {
      this.tabuladorForm.controls['imp_Subcontrato_extr'].disable();
    }
  }
  clearLocalStorageData() {
    localStorage.removeItem('DatosClientesProspecto');
  }
  Regresar() {
    this.router.navigateByUrl('/clientes-prospecto');
    this.clearLocalStorageData();
  }
  createConfiguracionFormGroup(configuracion: any): FormGroup {
    return this.fb.group({
      id: [configuracion.id], // Asegúrate de que idx_respaldo_confunidad esté correctamente definido aquí
      nombre: [configuracion.nombre, ''],
      importeCasetaConfiguracion: [configuracion.importe, Validators.required]
    });
  }

  addImporteForm(data: FormGroup, index: number) {
    this.objImporteCaseta.push({
      id_configuracion:data.get('id')?.value,
      importeCasetaConfiguracion:data.get('importeCasetaConfiguracion')?.value
    });
    this.importesForms.splice(index, 1);
    this.message.add({ severity: 'success', summary: 'Importe Agregado', detail: 'Importe Agregado Correctamente' });
  }

  Config(){
    this.importesForms = this.configuracionesSeleccionadas.map(configuracion => {
      const configuracionUnidad = this.cat_ConfiguracionUnidades.find(c => c.idx_respaldo_confunidad === configuracion);
      return this.createConfiguracionFormGroup({ id: configuracion, nombre: configuracionUnidad?.nombre });
    });
  }

  /*this.objdevueltoCiudad = obj.estadoMunicipio.map((indicador: any) => ({
              municipio: indicador.municipio,
              lv: indicador.lv,
              v_3_5: indicador.v_3_5,
              rabon: indicador.rabon,
              th: indicador.th,
              _53FT: indicador._53FT,
              usuario: indicador.usuario,
              celular: indicador.celular
            })); */
  
  handleChange(event: any) {
    /*const control = this.importesForms;
    control.clear(); // Limpiar el FormArray antes de agregar nuevos elementos

    const selectedConfigurations = this.cat_ConfiguracionUnidades.filter(configuracion => 
      configuracion.idx_respaldo_confunidad !== undefined && 
      this.configuracionesSeleccionadas.includes(configuracion.idx_respaldo_confunidad)
    );

    selectedConfigurations.forEach(configuracion => {
      control.push(this.createImporteForm(configuracion));
    });*/
    
    
    
    // Verifica si hay elementos seleccionados
    if (event.value.length >= 1) {
      // Habilita los campos si hay elementos seleccionados
      //this.tabuladorForm.get('Area')?.enable();
      //this.tabuladorForm.get('origen')?.enable();
      //this.tabuladorForm.get('destino')?.enable();
      this.tabuladorForm.get('kilometros')?.enable();
      this.tabuladorForm.get('peaje')?.enable();
      this.tabuladorForm.get('num_choferes')?.enable();
      this.tabuladorForm.get('num_auxiliares')?.enable();
      this.tabuladorForm.get('tipo_cliente')?.enable();
      this.tabuladorForm.get('hrs_carga_descarga')?.enable();
      this.tabuladorForm.get('porcentaje_UTD')?.enable();
      this.tabuladorForm.get('comision_cliente')?.enable();
    } else if (event.value.length == 0) {
      // Deshabilita los campos si no hay elementos seleccionados
      //this.tabuladorForm.get('Area')?.disable();
      //this.tabuladorForm.get('origen')?.disable();
      //this.tabuladorForm.get('destino')?.disable();
      this.tabuladorForm.get('kilometros')?.disable();
      this.tabuladorForm.get('peaje')?.disable();
      this.tabuladorForm.get('num_choferes')?.disable();
      this.tabuladorForm.get('num_auxiliares')?.disable();
      this.tabuladorForm.get('tipo_cliente')?.disable();
      this.tabuladorForm.get('porcentaje_UTD')?.disable();
      this.tabuladorForm.get('hrs_carga_descarga')?.disable();
      this.tabuladorForm.get('comision_cliente')?.disable();
    }
  }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  cancelarVentanaEditarModal(){
    this.dspEditarTAbulado=false;
    this.EditarTabuladoForm.reset();
    this.configuracionesSeleccionadas=[];
    this.objImporteCaseta=[];
    this.dspTabulador = false;
    this.dspTabuladorDiario = false;
    this.importesForms = [];
    this.tablaRespuesta = [...this.tablaVacia];
    this.uniqueConfigurations = [...this.tablaVacia];
    this.dspTablaRegreso = false;
    this.idruta = 0; 
    this.TablaRespuestaEditar = [...this.tablaVacia];
  }

  cancelarVentanaModal() {
    //this.texto_operacion = '';
    this.tabuladorForm.reset();
    this.configuracionesSeleccionadas=[];
    this.objImporteCaseta=[];
    this.dspTabulador = false;
    this.dspTabuladorDiario = false;
    this.importesForms = [];
    this.tablaRespuesta = [...this.tablaVacia];
    this.uniqueConfigurations = [...this.tablaVacia];
    this.dspTablaRegreso = false;
    this.idruta = 0; //Se pone para que se reinicie cuando le doy a la X en el frm 
  }
}
